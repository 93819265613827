import ApiService from './apiService';
import { CreditReportTransaction } from '../models/credit/credit-report-transaction';
import { CreditReportRequest } from '../models/credit/credit-report-request';
import { CreditReportResponse } from '../models/credit/credit-report-response';

class CreditService extends ApiService {
  private static instance?: CreditService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!CreditService.instance) {
      CreditService.instance = new CreditService();
    }
    return CreditService.instance;
  }

  searchTransactions(startDate?: Date, endDate?: Date): Promise<CreditReportTransaction[]> {
    const params = {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    };
    return this.client.get(`credit/transactions`, params).then((response) => response.data);
  }

  getCreditReport(request: CreditReportRequest): Promise<CreditReportResponse> {
    return this.client.post('credit/report', request).then((response) => response.data);
  }
}

export default CreditService;
