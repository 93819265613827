import ApiService from './apiService';
import { PriceSettings } from '../models/client/price-settings';

class PriceSettingsService extends ApiService {
  private static instance?: PriceSettingsService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!PriceSettingsService.instance) {
      PriceSettingsService.instance = new PriceSettingsService();
    }
    return PriceSettingsService.instance;
  }

  getAll(): Promise<PriceSettings[]> {
    return this.client.get(`/price-settings`).then((response) => response.data);
  }

  get(id: string): Promise<PriceSettings> {
    return this.client.get(`/price-settings/${id}`).then((response) => response.data);
  }

  create(settings: PriceSettings): Promise<void> {
    return this.client.post(`/price-settings`, settings);
  }

  update(settings: PriceSettings): Promise<void> {
    return this.client.put(`price-settings/${settings.clientId}`, settings);
  }

  delete(id: string): Promise<void> {
    return this.client.delete(`/price-settings/${id}`);
  }
}

export default PriceSettingsService;
