import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Messages } from 'primereact/messages';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import ClientService from '../../services/clientService';
import CreditSettingsService from '../../services/creditSettingsService';
import LpaSettingsService from '../../services/lpaSettingsService';
import DuSettingsService from '../../services/duSettingsService';
import { AppCard, AppPage } from '../../components/general';
import { DataTable, TableDefinition, DataTableSearchCreateHeader } from '../../components/datatable';
import { Client } from '../../models/client/client';

const Clients = (): JSX.Element => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  const clientService = ClientService.getInstance();
  const creditSettingsService = CreditSettingsService.getInstance();
  const lpaSettingsService = LpaSettingsService.getInstance();
  const duSettingsService = DuSettingsService.getInstance();

  useEffectOnce(() => {
    getClients();
  });

  const getClients = (): void => {
    setLoading(true);
    clientService
      .getAll()
      .then((clients) => {
        setClients(clients);
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onAdd = () => {
    navigate(`/clients/new`);
  };

  const onEdit = (clientId: string) => {
    navigate(`/clients/${clientId}`);
  };

  const onDelete = (client: Client) => {
    confirmDialog({
      message: `Are you sure you want to delete this client: ${client.clientName}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoading(true);
        Promise.all([
          clientService.delete(client.clientId!),
          creditSettingsService.delete(client.clientId!),
          lpaSettingsService.delete(client.clientId!),
          duSettingsService.delete(client.clientId!),
        ])
          .then(() => getClients())
          .catch((error) => {
            if (error.statusCode === 404) {
              getClients();
            } else {
              showError(error.message);
            }
          })
          .finally(() => setLoading(false));
      },
    });
  };

  const actionBodyTemplate = (client: Client) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => onEdit(client.clientId!)}
          tooltip="Edit Client"
          tooltipOptions={{ position: 'left' }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() => onDelete(client)}
          tooltip="Delete Client"
          tooltipOptions={{ position: 'left' }}
          severity="danger"
        />
      </div>
    );
  };

  const tableDef: TableDefinition<Client> = {
    columns: [
      {
        field: 'clientId',
        header: 'Client Id',
        dataType: 'text',
      },
      {
        field: 'clientName',
        header: 'Name',
        dataType: 'text',
      },
      {
        field: 'updatedDate',
        header: 'Updated Date',
        dataType: 'date',
      },
      {
        field: 'updatedBy',
        header: 'Updated By',
        dataType: 'text',
      },
      {
        header: 'Actions',
        body: actionBodyTemplate,
        dataType: 'none',
        sortable: false,
        filter: false,
      },
    ],
  };

  return (
    <AppPage title="Clients" breadcrumbs="Admin - Clients">
      <AppCard>
        <Messages ref={errors} />
        <ConfirmDialog />
        <DataTable
          loading={loading}
          header={<DataTableSearchCreateHeader onCreate={onAdd} onSearchChange={setSearch} />}
          tableDef={tableDef}
          data={clients}
          globalFilterValue={search}
        />
      </AppCard>
    </AppPage>
  );
};

export default Clients;
