import { useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const DataTableSearchCreateHeader = (props: {
  onCreate: () => void;
  onSearchChange: (value: string) => void;
}): JSX.Element => {
  const { onCreate, onSearchChange } = props;

  const [search, setSearch] = useState<string>('');

  return (
    <div className="flex flex-col lg:flex-row justify-between p-input-filled">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          className="min-w-[250px]"
          type="Search..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onSearchChange(e.target.value);
          }}
          placeholder="Search..."
        />
      </span>
      <div>
        <Button icon="pi pi-plus" label="Add New" onClick={onCreate} />
      </div>
    </div>
  );
};

export default DataTableSearchCreateHeader;
