import { useState, useRef } from 'react';

import { Messages } from 'primereact/messages';

import useEffectOnce from '../../hooks/useEffectOnce';
import CreditSettingsService from '../../services/creditSettingsService';
import { AppCard, AppPage } from '../../components/general';
import { CreditAccount } from '../../models/client/credit-settings';
import CreditReportAccounts from '../client/CreditAccounts';

const CreditAccounts = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<CreditAccount[]>([]);

  const creditSettingsService = CreditSettingsService.getInstance();

  useEffectOnce(() => {
    loadAccounts();
  });

  const loadAccounts = () => {
    setLoading(true);
    creditSettingsService
      .getAccounts()
      .then((accounts) => {
        setAccounts(accounts);
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onChange = (accounts?: CreditAccount[]) => {
    setLoading(true);
    creditSettingsService
      .updateAccounts(accounts)
      .then(() => {
        loadAccounts();
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  };

  return (
    <AppPage title="Credit Accounts" breadcrumbs="Home - Credit - Accounts">
      <AppCard>
        <Messages ref={errors} />
        <CreditReportAccounts loading={loading} accounts={accounts} onAccountChange={onChange} size="normal" />
      </AppCard>
    </AppPage>
  );
};

export default CreditAccounts;
