import { useState, useRef } from 'react';
import { endOfDay } from 'date-fns';

import { Messages } from 'primereact/messages';

import useEffectOnce from '../../hooks/useEffectOnce';
import CreditService from '../../services/creditService';
import { AppCard, AppPage } from '../../components/general';
import { DataTable, TableDefinition, DataTableSearchHeader } from '../../components/datatable';
import { CreditReportTransaction } from '../../models/credit/credit-report-transaction';
import CalendarRange, { DefaultRangeSelectType } from '../../components/common/CalendarRange';

const CreditReports = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Array<CreditReportTransaction>>([]);
  const [search, setSearch] = useState<string>('');

  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), 1);
  const end = endOfDay(new Date(now.getFullYear(), now.getMonth() + 1, 0));
  const [dateRange, setDateRange] = useState<Date[] | undefined>([start, end]);

  const creditService = CreditService.getInstance();

  useEffectOnce(() => {
    getCreditReports(start, end);
  });

  const getCreditReports = (start: Date, end: Date): void => {
    setLoading(true);
    creditService
      .searchTransactions(start, end)
      .then((transactions) => {
        setTransactions(transactions);
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const tableDef: TableDefinition<CreditReportTransaction> = {
    columns: [
      {
        field: 'timestamp',
        header: 'Timestamp',
        dataType: 'timestamp',
      },
      {
        field: 'lenderLoanNumber',
        header: 'Lender Loan Number',
        dataType: 'text',
      },
      {
        field: 'creditReferenceNumber',
        header: 'Credit Reference Number',
        dataType: 'text',
      },
      {
        field: 'creditAction',
        header: 'Credit Action',
        dataType: 'text',
      },
      {
        field: 'creditType',
        header: 'Credit Type',
        dataType: 'text',
      },
      {
        header: 'Borr Last',
        body: (transaction) => [...new Set(transaction.borrowers?.map((b) => b.lastName))].join(', '),
        dataType: 'text',
        filter: false,
        sortable: false,
      },
      {
        header: 'State',
        body: (transaction) => [...new Set(transaction.borrowers?.map((b) => b.addressState))].join(', '),
        dataType: 'text',
        filter: false,
        sortable: false,
      },
      {
        field: 'loanType',
        header: 'Loan Type',
        dataType: 'text',
      },
      {
        header: 'Bureau(s)',
        body: (transaction) =>
          transaction.borrowers?.map((b) => {
            const bureaus = b.creditScores
              ?.map((c) => {
                if (!c.creditBureau) return '-';

                return (
                  {
                    Experian: 'EX',
                    TransUnion: 'TU',
                    Equifax: 'EQ',
                  }[c.creditBureau] ?? '-'
                );
              })
              .join(', ');
            return <div>{bureaus}</div>;
          }),
        dataType: 'text',
        filter: false,
        sortable: false,
      },
      {
        header: 'Score(s)',
        body: (transaction) =>
          transaction.borrowers?.map((b) => {
            const scores = b.creditScores?.map((c) => c.score ?? '-').join(', ');
            return <div>{scores}</div>;
          }),
        dataType: 'text',
        filter: false,
        sortable: false,
      },
      {
        field: 'totalMonthlyPaymentAmount',
        header: 'Total MPA',
        dataType: 'numeric',
      },
      {
        field: 'totalUnpaidBalanceAmount',
        header: 'Total UBA',
        dataType: 'numeric',
      },
      {
        field: 'correlationId',
        header: 'Correlation Id',
        dataType: 'text',
      },
    ],
  };

  const headerTemplate = () => {
    return (
      <div className="flex flex-row justify-between items-center">
        <DataTableSearchHeader onSearchChange={setSearch} />
        <div className="p-input-filled">
          <CalendarRange
            rangeSelectOptions={[
              DefaultRangeSelectType.PastWeek,
              DefaultRangeSelectType.Past2Weeks,
              DefaultRangeSelectType.Past30Days,
              DefaultRangeSelectType.CurrentMonth,
            ]}
            value={dateRange}
            onValueChange={(value) => {
              if (!!value && value.length === 2) {
                getCreditReports(value[0], value[1]);
              }
              setDateRange(value);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <AppPage title="Credit Transactions" breadcrumbs="Home - Credit - Transactions">
      <AppCard>
        <Messages ref={errors} />
        <DataTable
          loading={loading}
          header={headerTemplate()}
          tableDef={tableDef}
          data={transactions}
          globalFilterValue={search}
        />
      </AppCard>
    </AppPage>
  );
};

export default CreditReports;
