import ApiService from './apiService';
import { UserAccountDto } from '../models/user-account';

class UserAccountService extends ApiService {
  private static instance?: UserAccountService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!UserAccountService.instance) {
      UserAccountService.instance = new UserAccountService();
    }
    return UserAccountService.instance;
  }

  getAll(): Promise<UserAccountDto[]> {
    return this.client.get(`/user-accounts`).then((response) => response.data);
  }

  get(id: string): Promise<UserAccountDto> {
    return this.client.get(`/user-accounts/${id}`).then((response) => response.data);
  }

  create(account: UserAccountDto): Promise<void> {
    return this.client.post(`/user-accounts`, account);
  }

  update(account: UserAccountDto): Promise<void> {
    return this.client.put(`user-accounts/${account.cognitoId}`, account);
  }

  delete(id: string): Promise<void> {
    return this.client.delete(`/user-accounts/${id}`);
  }
}

export default UserAccountService;
