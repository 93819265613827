import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';

const withGroupAuthorizer = (WrappedComponent: (props?: any) => JSX.Element, groups: Array<string>) => {
  const WithGroupAuthorizer = (WrappedComponent: (props?: any) => JSX.Element, groups: Array<string>) => {
    const { user } = useAuthenticator((context) => [context.user]);
    const userGroups = user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'];

    if (userGroups && groups.some((g) => userGroups.includes(g))) {
      return (
        <React.Fragment>
          <WrappedComponent />
        </React.Fragment>
      );
    }

    return <Navigate to="/401" />;
  };
  return WithGroupAuthorizer(WrappedComponent, groups);
};

export default withGroupAuthorizer;
