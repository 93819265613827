import { EffectivePrice } from './effective-price';

export class LpaSettings {
  clientId?: string;
  isActive: boolean = false;
  priceHistory?: EffectivePrice[] = [];
  accountIdentifier?: string;
  accountPassword?: string;
  apiClientId?: string;
  apiClientSecret?: string;
  updatedDate?: Date | string;
  updatedBy?: string;

  constructor(clientId: string) {
    this.clientId = clientId;
  }
}
