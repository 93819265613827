export class PriceSettings {
  clientId?: string;
  isActive: boolean = false;
  subdomain?: string;
  updatedDate?: Date | string;
  updatedBy?: string;

  constructor(clientId: string) {
    this.clientId = clientId;
  }
}
