import React, { useRef } from 'react';
import { add, endOfDay, format, startOfDay } from 'date-fns';

import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';
import { Nullable } from 'primereact/ts-helpers';

export enum DefaultRangeSelectType {
  CurrentMonth,
  PastWeek,
  Past2Weeks,
  Past30Days,
}
export type RangeSelectOption = {
  label: string;
  startDate: Date;
  endDate: Date;
};

const CalendarRange = (props: {
  className?: string;
  label?: string;
  rangeSelectOptions: (DefaultRangeSelectType | RangeSelectOption)[];
  value: Date[] | undefined;
  onValueChange: (dateRange: Date[] | undefined) => void;
}) => {
  const op = useRef<OverlayPanel>(null);

  const options: RangeSelectOption[] = props.rangeSelectOptions.map((option) => {
    const now = endOfDay(new Date());
    if ((option as DefaultRangeSelectType) in DefaultRangeSelectType) {
      switch (option) {
        case DefaultRangeSelectType.CurrentMonth:
          const start = new Date(now.getFullYear(), now.getMonth(), 1);
          const end = endOfDay(new Date(now.getFullYear(), now.getMonth() + 1, 0));
          return {
            label: 'Current Month',
            startDate: start,
            endDate: end,
          };
        case DefaultRangeSelectType.PastWeek:
          return {
            label: 'Past Week',
            startDate: startOfDay(add(now, { days: -7 })),
            endDate: now,
          };
        case DefaultRangeSelectType.Past2Weeks:
          return {
            label: 'Past 2 Weeks',
            startDate: startOfDay(add(now, { days: -14 })),
            endDate: now,
          };
        case DefaultRangeSelectType.Past30Days:
          return {
            label: 'Past 30 Days',
            startDate: startOfDay(add(now, { days: -30 })),
            endDate: now,
          };
        default:
          throw new Error(`Missing date range for type: ${option}`);
      }
    }

    return option as RangeSelectOption;
  });

  const onCalendarChange = (value: Nullable<(Date | null)[]>) => {
    let range = !value ? undefined : Array.isArray(value) ? value : [value];
    if (range) {
      range = range?.filter((d) => d);
      range.forEach((d, i) => {
        if (i === 0) {
          range![i] = startOfDay(d as Date);
        } else if (i === 1) {
          range![i] = endOfDay(d as Date);
        }
      });
    }
    props.onValueChange(range as Date[]);
  };

  const getDisplay = () => {
    const match = options.find(
      (option) =>
        props.value?.length === 2 &&
        option.startDate.getTime() === props.value[0].getTime() &&
        option.endDate.getTime() === props.value[1].getTime()
    );

    if (!!match) {
      return match.label;
    }

    return props.value?.map((d) => format(d, 'MM/dd/yy')).join('-');
  };

  return (
    <React.Fragment>
      <span className="font-semibold text-gray-800 text-lg pr-2">{props.label ?? 'Date Range:'}</span>
      <InputText
        className={classNames('hover:cursor-pointer', props.className)}
        readOnly
        autoComplete="off"
        inputMode="none"
        onFocus={(e) => op.current?.toggle(e)}
        value={getDisplay()}
      />
      <OverlayPanel ref={op}>
        <div className="flex flex-row">
          <ul className="py-4 w-[150px]">
            {options.map((option) => {
              const match =
                props.value?.length === 2 &&
                option.startDate.getTime() === props.value[0].getTime() &&
                option.endDate.getTime() === props.value[1].getTime();
              return (
                <li
                  key={option.label}
                  className={classNames('px-5 py-2  hover:cursor-pointer hover:bg-[#F9F9F9] hover:text-[#009ef7]', {
                    '!bg-[#009ef7] !text-white': !!match,
                  })}
                  onClick={() => onCalendarChange([option.startDate, option.endDate])}
                >
                  {option.label}
                </li>
              );
            })}
          </ul>
          <Calendar inline selectionMode="range" value={props.value} onChange={(e) => onCalendarChange(e.value)} />
        </div>
      </OverlayPanel>
    </React.Fragment>
  );
};

export default CalendarRange;
