import { useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { confirmDialog } from 'primereact/confirmdialog';

import useEffectOnce from '../../hooks/useEffectOnce';
import CreditSettingsService from '../../services/creditSettingsService';
import { CreditSettings } from '../../models/client/credit-settings';
import { FormContainer, FormSelectButton } from '../../components/form';
import { YesNoOptions } from '../../components/form/FormSelectButton';
import CreditReportAccounts from './CreditAccounts';
import PriceHistory from './PriceHistory';
import CostSheet from './CostSheet';

const EditCreditSettings = (props: { clientId: string }): JSX.Element => {
  const { clientId } = props;
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const creditSettingsService = CreditSettingsService.getInstance();

  useEffectOnce(() => {
    setLoading(true);
    creditSettingsService
      .get(clientId)
      .then((client) => {
        reset(client);
        setIsEdit(true);
      })
      .catch((error) => {
        if (error.statusCode === 404) {
          reset(new CreditSettings(clientId));
        } else {
          showError(error.message);
        }
      })
      .finally(() => setLoading(false));
  });

  const validationSchema = yup.object({});

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<CreditSettings>({
    resolver: yupResolver<any>(validationSchema),
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSubmit = handleSubmit((settings: CreditSettings) => {
    confirmDialog({
      message: 'Are you sure you want to save this credit settings?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoading(true);
        const addOrUpdate = !isEdit ? creditSettingsService.create(settings) : creditSettingsService.update(settings);

        setLoading(true);
        addOrUpdate
          .then(() => window.location.reload())
          .catch((error) => {
            showError(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  });

  const navigateToClients = () => {
    navigate('/clients');
  };

  return (
    <FormContainer loading={loading} errors={errors} onSubmit={onSubmit} onCancel={navigateToClients}>
      <div className="!grid grid-cols-1">
        <FormSelectButton control={control} errors={formErrors} field="isActive" label="Is Active" options={YesNoOptions} />
        <Controller
          name="accounts"
          control={control}
          render={({ field }) => (
            <CreditReportAccounts
              includeHeader={true}
              accounts={field.value}
              onAccountChange={field.onChange}
              className="py-2"
            />
          )}
        />
        <Controller
          name="priceHistory"
          control={control}
          render={({ field }) => <PriceHistory priceHistory={field.value} onChange={field.onChange} className="py-2" />}
        />
        <Controller
          name="costSheet"
          control={control}
          render={({ field }) => <CostSheet costSheet={field.value} onChange={field.onChange} className="py-2" />}
        />
      </div>
    </FormContainer>
  );
};

export default EditCreditSettings;
