import { Control, Controller, FieldErrors, FieldPath, FieldValues, get } from 'react-hook-form';

import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';

export type FormCalendarProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  className?: string;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  field: TName;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  showTime?: boolean;
  isDateOnly?: boolean;
};

const FormCalendar = <TFieldValues extends FieldValues>({ ...props }: FormCalendarProps<TFieldValues>): JSX.Element => {
  let { className, control, field, errors, label, disabled, placeholder, showTime, isDateOnly } = props;

  const errorTemplate = () => {
    const error = get(errors, field);
    return error && <small className="p-error">{error?.message as string}</small>;
  };

  const getDatePartOnly = (date?: Date | null): Date | null => {
    if (!date) return null;

    const val = new Date(date);

    return new Date(val.getUTCFullYear(), val.getUTCMonth(), val.getUTCDate());
  };

  const toUtcEpoch = (date?: Date): Date | null => {
    if (!date) return null;

    date.setUTCHours(0, 0, 0, 0);
    return date;
  };

  return (
    <div className={className ?? 'field'}>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames('font-medium', { 'p-error': fieldState.error })}>
              {label}
            </label>
            <Calendar
              id={field.name}
              value={isDateOnly ? getDatePartOnly(field.value) : field.value}
              onChange={(e) => {
                const val = isDateOnly ? toUtcEpoch(e.value as Date) : e.value;
                field.onChange(val as any);
              }}
              className={classNames({ 'p-invalid': fieldState.error })}
              disabled={disabled}
              placeholder={placeholder}
              showTime={isDateOnly ? false : showTime ?? false}
            />
          </>
        )}
      />
      {errorTemplate()}
    </div>
  );
};

export default FormCalendar;
