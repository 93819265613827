import ApiService from './apiService';
import { DuSettings } from '../models/client/du-settings';

class DuSettingsService extends ApiService {
  private static instance?: DuSettingsService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!DuSettingsService.instance) {
      DuSettingsService.instance = new DuSettingsService();
    }
    return DuSettingsService.instance;
  }

  getAll(): Promise<DuSettings[]> {
    return this.client.get(`/du-settings`).then((response) => response.data);
  }

  get(id: string): Promise<DuSettings> {
    return this.client.get(`/du-settings/${id}`).then((response) => response.data);
  }

  create(settings: DuSettings): Promise<void> {
    return this.client.post(`/du-settings`, settings);
  }

  update(settings: DuSettings): Promise<void> {
    return this.client.put(`du-settings/${settings.clientId}`, settings);
  }

  delete(id: string): Promise<void> {
    return this.client.delete(`/du-settings/${id}`);
  }
}

export default DuSettingsService;
