import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Messages } from 'primereact/messages';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import ReportAgencyService from '../../services/reportAgencyService';
import { AppCard, AppPage } from '../../components/general';
import { DataTable, TableDefinition, DataTableSearchCreateHeader } from '../../components/datatable';
import { CreditReportingAgency } from '../../models/credit/credit-reporting-agency';

const CreditReportingAgencies = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportingAgencies, setReportingAgencies] = useState<CreditReportingAgency[]>([]);
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  const reportAgencytService = ReportAgencyService.getInstance();

  useEffectOnce(() => {
    getReportingAgencies();
  });

  const getReportingAgencies = (): void => {
    setLoading(true);
    reportAgencytService
      .getAll()
      .then((reportingAgencies) => {
        setReportingAgencies(reportingAgencies);
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onAdd = () => {
    navigate(`/credit-reporting-agencies/new`);
  };

  const onEdit = (creditReportingAgencyId: string) => {
    navigate(`/credit-reporting-agencies/${creditReportingAgencyId}`);
  };

  const onDelete = (reportingAgency: CreditReportingAgency) => {
    confirmDialog({
      message: `Are you sure you want to delete this credit reporting agency: ${reportingAgency.creditReportingAgencyName}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoading(true);
        reportAgencytService
          .delete(reportingAgency.creditReportingAgencyId!)
          .then(() => getReportingAgencies())
          .catch((error) => showError(error.message))
          .finally(() => setLoading(false));
      },
    });
  };

  const actionBodyTemplate = (reportingAgency: CreditReportingAgency) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => onEdit(reportingAgency.creditReportingAgencyId!)}
          tooltip="Edit Agency"
          tooltipOptions={{ position: 'left' }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() => onDelete(reportingAgency)}
          tooltip="Delete Agency"
          tooltipOptions={{ position: 'left' }}
          severity="danger"
        />
      </div>
    );
  };

  const tableDef: TableDefinition<CreditReportingAgency> = {
    columns: [
      {
        field: 'creditReportingAgencyId',
        header: 'Agency Id',
        dataType: 'text',
      },
      {
        field: 'creditReportingAgencyName',
        header: 'Agency Name',
        dataType: 'text',
      },
      {
        field: 'creditReportingCompany',
        header: 'CRC',
        dataType: 'text',
      },
      {
        field: 'isActive',
        header: 'Active',
        dataType: 'boolean',
      },
      {
        field: 'duIdentifier',
        header: 'DU Id',
        dataType: 'text',
      },
      {
        field: 'lpaIdentifier',
        header: 'LPA Id',
        dataType: 'text',
      },
      {
        field: 'meridianLinkBaseUrl',
        header: 'Meridian Link Url',
        dataType: 'text',
      },
      {
        field: 'updatedDate',
        header: 'Updated Date',
        dataType: 'date',
      },
      {
        field: 'updatedBy',
        header: 'Updated By',
        dataType: 'text',
      },
      {
        header: 'Actions',
        body: actionBodyTemplate,
        dataType: 'none',
        sortable: false,
        filter: false,
      },
    ],
  };

  return (
    <AppPage title="Credit Reporting Agencies" breadcrumbs="Admin - Credit Reporting Agencies">
      <AppCard>
        <Messages ref={errors} />
        <ConfirmDialog />
        <DataTable
          loading={loading}
          header={<DataTableSearchCreateHeader onCreate={onAdd} onSearchChange={setSearch} />}
          tableDef={tableDef}
          data={reportingAgencies}
          globalFilterValue={search}
        />
      </AppCard>
    </AppPage>
  );
};

export default CreditReportingAgencies;
