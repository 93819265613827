export class CreditReportingAgency {
  creditReportingAgencyId?: string = '';
  creditReportingAgencyName?: string = '';
  creditReportingCompany?: CreditReportingCompany;
  isActive: boolean = false;
  duIdentifier?: string;
  lpaIdentifier?: string;
  meridianLinkBaseUrl?: string;
  updatedDate?: Date | string;
  updatedBy?: string;
}

export class CreditReportingAgencyBasicView {
  creditReportingAgencyId?: string = '';
  creditReportingAgencyName?: string = '';
  creditReportingCompany?: CreditReportingCompany;
}

export enum CreditReportingCompany {
  Xactus = 'Xactus',
  InformativeResearch = 'Informative Research',
  FactualData = 'Factual Data',
  MeridianLink = 'MeridianLink',
}
