import { useState, useEffect } from 'react';

import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { ColumnBodyOptions } from 'primereact/column';

import { DataTable, TableDefinition } from '../../components/datatable';
import EditCreditCostItem, { CreditCostItemEntry } from './EditCreditCostItem';
import { CreditCostItem } from '../../models/client/credit-settings';

const CostSheet = (props: {
  className?: string;
  costSheet?: CreditCostItem[];
  onChange: (costSheet?: CreditCostItem[]) => void;
}): JSX.Element => {
  const { className, onChange } = props;

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [costSheet, setCostSheet] = useState<CreditCostItem[] | undefined>(props.costSheet);
  const [editEntry, setEditEntry] = useState<CreditCostItemEntry>({ item: new CreditCostItem(), index: -1 });

  useEffect(() => {
    setCostSheet(props.costSheet);
  }, [props.costSheet]);

  const onAdd = () => {
    setEditEntry({ item: new CreditCostItem(), index: -1 });
    setShowDialog(true);
  };

  const onEdit = (item: CreditCostItem, index: number) => {
    setEditEntry({ item: item, index: index });
    setShowDialog(true);
  };

  const onDelete = (item: CreditCostItem, index: number) => {
    confirmDialog({
      message: 'Are you sure you want to delete this?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const sheet = [...costSheet!];
        sheet.splice(index, 1);
        onChange(sheet);
      },
    });
  };

  const actionBodyTemplate = (item: CreditCostItem, options: ColumnBodyOptions) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => onEdit(item, options.rowIndex)}
          tooltip="Edit"
          tooltipOptions={{ position: 'left' }}
          type="button"
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() => onDelete(item, options.rowIndex)}
          tooltip="Delete"
          tooltipOptions={{ position: 'left' }}
          severity="danger"
          type="button"
        />
      </div>
    );
  };

  const tableDef: TableDefinition<CreditCostItem> = {
    columns: [
      {
        field: 'creditReportingAgencyId',
        header: 'CRA',
        dataType: 'text',
      },
      {
        field: 'creditType',
        header: 'Credit Type',
        dataType: 'text',
      },
      {
        field: 'borrowerType',
        header: 'Borrower Type',
        dataType: 'text',
      },
      {
        field: 'creditBureaus',
        header: 'Bureau(s)',
        body: (item) => item.creditBureaus?.join(', '),
        dataType: 'text',
        filter: false,
        sortable: false,
      },
      {
        field: 'cost',
        header: 'Cost',
        dataType: 'currency',
      },
      {
        header: 'Actions',
        body: actionBodyTemplate,
        dataType: 'none',
        sortable: false,
        filter: false,
      },
    ],
  };

  return (
    <>
      <DataTable
        header={
          <div className="flex flex-col lg:flex-row justify-content-between align-items-center">
            <div className="font-semibold text-base">Cost Sheet</div>
            <div>
              <Button icon="pi pi-plus" label="Add New" onClick={onAdd} type="button" outlined size="small" />
            </div>
          </div>
        }
        headerClassName="mb-2"
        tableDef={tableDef}
        data={costSheet ?? []}
        paginator={false}
        className={className}
        size="small"
      />
      {showDialog && (
        <EditCreditCostItem
          entry={editEntry}
          onSave={(entry) => {
            const sheet = [...costSheet!];
            if (entry.index === -1) {
              sheet.push(entry.item);
            } else {
              sheet[entry.index] = entry.item;
            }
            onChange(sheet);
            setShowDialog(false);
          }}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </>
  );
};

export default CostSheet;
