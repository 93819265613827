import { BillingItem } from '../models/billing/billing-item';
import ApiService from './apiService';

class BillingService extends ApiService {
  private static instance?: BillingService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!BillingService.instance) {
      BillingService.instance = new BillingService();
    }
    return BillingService.instance;
  }

  getItemization(startDate?: Date, endDate?: Date): Promise<BillingItem[]> {
    const params = {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    };
    return this.client.get(`billing/itemization`, params).then((response) => response.data);
  }
}

export default BillingService;
