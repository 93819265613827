import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';

import { FormDropdown, FormInputNumber, FormMultiSelect } from '../../components/form';
import { BorrowerType, CreditCostItem } from '../../models/client/credit-settings';
import { CreditReportingAgencyBasicView } from '../../models/credit/credit-reporting-agency';
import useEffectOnce from '../../hooks/useEffectOnce';
import ReportAgencyService from '../../services/reportAgencyService';
import { EnumToOptions } from '../../utils/form-helper';
import { CreditType } from '../../models/credit/credit-report-transaction';
import { CreditBureau } from '../../models/credit/credit-report-request';

export type CreditCostItemEntry = {
  item: CreditCostItem;
  index: number;
};

const EditCreditCostItem = (props: {
  entry: CreditCostItemEntry;
  onSave: (entry: CreditCostItemEntry) => void;
  onCancel: () => void;
}): JSX.Element => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [entry, setEntry] = useState<CreditCostItemEntry>(props.entry);
  const [cras, setCras] = useState<CreditReportingAgencyBasicView[]>([]);

  const reportAgencytService = ReportAgencyService.getInstance();

  useEffectOnce(() => {
    setLoading(true);
    reportAgencytService
      .getAllBasicView()
      .then((response) => {
        const _cras = response.filter(
          (cra) => response.findIndex((account) => account.creditReportingAgencyId === cra.creditReportingAgencyId) !== -1
        );
        setCras(_cras);
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  });

  useEffect(() => {
    setEntry(props.entry);
    reset(props.entry.item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entry]);

  const validationSchema = yup.object({
    creditReportingAgencyId: yup.string().nullable().required(),
    creditType: yup.mixed<CreditType>().oneOf(Object.values(CreditType)).required(),
    borrowerType: yup.mixed<BorrowerType>().oneOf(Object.values(BorrowerType)).required(),
    creditBureaus: yup.array().min(1, 'Must select at least one Credit Bureau'),
    cost: yup.number().nullable().required().min(0),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    getValues,
  } = useForm<CreditCostItem>({
    resolver: yupResolver<any>(validationSchema),
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSubmit = handleSubmit((item: CreditCostItem) => {
    props.onSave({ item: getValues(), index: entry.index });
  });

  return (
    <Dialog
      visible={true}
      style={{ width: '50vw' }}
      onHide={() => {}}
      footer={() => (
        <div className="text-right">
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-secondary p-button mr-3"
            onClick={props.onCancel}
            type="button"
          />
          <Button label="Save" onClick={onSubmit} icon="pi pi-check" className="p-button-primary p-button" type="button" />
        </div>
      )}
      closable={false}
    >
      {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
      <div className="grow p-fluid p-input-filled">
        <FormDropdown
          control={control}
          errors={formErrors}
          field="creditReportingAgencyId"
          label="CRA"
          options={cras}
          optionLabel="creditReportingAgencyName"
          optionValue="creditReportingAgencyId"
          showFilter={true}
        />
        <FormDropdown
          control={control}
          errors={formErrors}
          field="creditType"
          label="Credit Type"
          options={EnumToOptions(CreditType)}
        />
        <FormDropdown
          control={control}
          errors={formErrors}
          field="borrowerType"
          label="Borrower Type"
          options={EnumToOptions(BorrowerType)}
        />

        <FormMultiSelect
          control={control}
          errors={formErrors}
          field="creditBureaus"
          label="Credit Bureau(s)"
          options={EnumToOptions(CreditBureau)}
        />
        <FormInputNumber control={control} errors={formErrors} field="cost" label="Cost" mode="currency" currency="USD" />
      </div>
    </Dialog>
  );
};

export default EditCreditCostItem;
