import { useState } from 'react';

import { InputText } from 'primereact/inputtext';

const DataTableSearchHeader = (props: { onSearchChange: (value: string) => void }): JSX.Element => {
  const { onSearchChange } = props;

  const [search, setSearch] = useState<string>('');

  return (
    <div className="p-input-filled">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          className="min-w-[250px]"
          type="Search..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onSearchChange(e.target.value);
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  );
};

export default DataTableSearchHeader;
