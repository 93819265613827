import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import mezzoFull from '../../../assets/images/mezzo-full.png';
import { ReactComponent as MenuIcon } from '../../../assets/images/menu-icon.svg';

import { useAuthenticator } from '@aws-amplify/ui-react';

const Header = (props: { className?: string; isDesktop: boolean; onMenuIconClick: () => void }): JSX.Element => {
  const { className, onMenuIconClick } = props;
  const { signOut } = useAuthenticator((context) => [context.user]);

  const [isDesktop, setIsDesktop] = useState<boolean>(props.isDesktop);

  useEffect(() => {
    setIsDesktop(props.isDesktop);
  }, [props.isDesktop]);

  return (
    <div
      className={classNames(
        'flex flex-row justify-between items-center px-3 bg-[var(--topbar-bg-color)] shadow-[0_10px_30px_0_rgba(82,63,105,0.05)]',
        className
      )}
    >
      <div className="flex flex-row items-center gap-2">
        {!isDesktop && (
          <>
            <Button
              className="p-button-text text-gray-500 p-2 hover:text-gray-700 focus:shadow-none"
              onClick={() => onMenuIconClick()}
            >
              <MenuIcon title="menu" />
            </Button>
            <Link to="/">
              <img src={mezzoFull} alt="Logo" style={{ height: '30px' }} />
            </Link>
          </>
        )}
      </div>
      <Button className="p-button-text text-gray-500 p-2 hover:text-gray-700 focus:shadow-none" onClick={signOut}>
        <i className="pi pi-sign-out text-2xl"></i>
      </Button>
    </div>
  );
};

export default Header;
