import { ReactNode } from 'react';

import { classNames } from 'primereact/utils';

const AppPage = (props: {
  className?: string;
  title: string;
  breadcrumbs?: string;
  headerEnd?: ReactNode;
  children: ReactNode;
}): JSX.Element => {
  const { className, title, breadcrumbs, headerEnd, children } = props;

  return (
    <div className={classNames('flex flex-col grow p-5', className)}>
      <div className="flex align-items-center mb-4">
        <div className="flex-grow">
          <div className="font-semibold text-gray-800 text-2xl">{title}</div>
          {breadcrumbs && <div className="font-medium text-gray-500 text-base pt-1">{breadcrumbs}</div>}
        </div>
        <div>{headerEnd}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default AppPage;
