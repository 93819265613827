import { Link } from 'react-router-dom';

import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import { SidebarMenu } from '../sidebar';
import mezzoIcon from '../../../assets/images/mezzo-icon.png';
import mezzoFull from '../../../assets/images/mezzo-full.png';

const Sidebar = (props: {
  className?: string;
  isDesktop: boolean;
  minimized: boolean;
  locked: boolean;
  onHover: (lockOption: boolean) => void;
  onMenuIconClick: () => void;
}): JSX.Element => {
  const { className, isDesktop, minimized, locked, onHover, onMenuIconClick } = props;

  return (
    <nav
      onMouseEnter={(): void => onHover(true)}
      onMouseLeave={(): void => onHover(false)}
      className={classNames(
        'flex flex-col bg-[var(--sidebar-bg-color)] text-[var(--sidebar-link-text-color)] whitespace-nowrap',
        className
      )}
    >
      {isDesktop && (
        <div className="h-[75px] flex flex-row items-center px-4 border-b border-dashed border-gray-700">
          <Link to="/">
            <img className={!minimized ? 'hidden' : ''} src={mezzoIcon} alt="Logo" style={{ height: '30px' }} />
          </Link>
          <Link to="/">
            <img className={minimized ? 'hidden' : ''} src={mezzoFull} alt="Logo" style={{ height: '40px' }} />
          </Link>
          <Button
            className="absolute left-full translate-x-[-50%] w-[30px] h-[30px] flex justify-content-center p-0 bg-white text-gray-500 hover:text-gray-700 focus:shadow-none !border-[#F1F1F2]"
            onClick={() => onMenuIconClick()}
          >
            <i className={classNames('pi pi-angle-double-right transition-all duration-500 ease', locked && 'rotate-180')} />
          </Button>
        </div>
      )}
      <SidebarMenu className="flex-grow-1" minimized={minimized} />
      {/* <SidebarFooter minimized={minimized} /> */}
    </nav>
  );
};

export default Sidebar;
