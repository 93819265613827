export class EffectivePrice {
  effectiveDate: Date = new Date();
  price: number = 0;

  constructor() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    this.effectiveDate = today;
    this.price = 0;
  }
}
