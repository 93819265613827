import { MutableRefObject, ReactNode } from 'react';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';

import { ProgressBar } from 'primereact/progressbar';

const FormContainer = (props: {
  className?: string;
  loading: boolean;
  errors: MutableRefObject<any>;
  onSubmit: () => void;
  onCancel?: () => void;
  footer?: ReactNode;
  children: ReactNode;
}): JSX.Element => {
  const { className, loading, errors, onSubmit, onCancel, footer, children } = props;

  const footerTemplate = () => {
    if (!!footer) return footer;

    return (
      <>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-secondary p-button mr-3"
          onClick={onCancel}
          type="button"
        />
        <Button label="Save" icon="pi pi-check" className="p-button-primary p-button" type="submit" loading={loading} />
      </>
    );
  };

  return (
    <div className={className}>
      {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
      <Messages ref={errors} />
      <form onSubmit={onSubmit}>
        <div className="grow p-fluid p-input-filled">
          <div>{children}</div>
        </div>
        <div className="text-right">{footerTemplate()}</div>
      </form>
    </div>
  );
};

export default FormContainer;
