import { useState, Fragment } from 'react';

import { classNames } from 'primereact/utils';

import { SidebarMenuItem } from '.';
import { SidebarMenuProps } from '../../../models/sidebar/sidebar';
import { useAuthenticator } from '@aws-amplify/ui-react';

const SidebarMenu = (props: { className?: string; minimized: boolean }): JSX.Element => {
  const { className, minimized } = props;

  // lifted currentMenu state, as this impacts which SidebarMenu is open at a given time
  const [currentMenu, setCurrentMenu] = useState<string>('');
  const { user } = useAuthenticator((context) => [context.user]);

  const selectMenu = (id: string): void => {
    if (currentMenu === id) {
      setCurrentMenu('');
    } else {
      setCurrentMenu(id);
    }
  };

  const isAuthorized = (authorizedGroups?: Array<string>) => {
    if (!authorizedGroups) {
      return true;
    }

    const userGroups = user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'];
    return userGroups && authorizedGroups.some((g) => userGroups.includes(g));
  };

  const content = [
    {
      menuItems: [
        {
          label: 'Dashboard',
          to: '/dashboard',
          icon: 'pi-chart-bar',
        },
        {
          label: 'Credit',
          icon: 'pi-book',
          submenuItems: [
            {
              label: 'Emulator',
              to: '/credit/emulator',
            },
            {
              label: 'Accounts',
              to: '/credit/accounts',
            },
            {
              label: 'Transactions',
              to: '/credit/transactions',
            },
          ],
        },
        {
          label: 'Underwrite',
          to: '/underwrite',
          icon: 'pi-book',
        },
        {
          label: 'Billing',
          to: '/billing',
          icon: 'pi-money-bill',
        },
      ],
    },
    {
      header: 'Admin',
      authorizedGroups: ['Admin'],
      menuItems: [
        {
          label: 'Clients',
          to: '/clients',
          icon: 'pi-user',
        },
        {
          label: 'User Accounts',
          to: '/user-accounts',
          icon: 'pi-users',
        },
        {
          label: 'Credit Reporting Agencies',
          to: '/credit-reporting-agencies',
          icon: 'pi-user',
        },
      ],
    },
  ];

  return (
    <section className={classNames('overflow-y-auto nav-scrollbar p-4', className)}>
      {content.map((ele, idx) => {
        if (!isAuthorized(ele.authorizedGroups)) return null;

        const section = ele.menuItems;
        const sectionIdx = idx;
        return (
          <Fragment key={idx}>
            {ele.header && (
              // eslint-disable-next-line no-octal-escape
              <h2 className="uppercase font-bold overflow-x-hidden my-3 empty:before:content-['\200B']">
                {!minimized && ele.header}
              </h2>
            )}
            <>
              {section.map((ele: SidebarMenuProps, idx) => {
                return (
                  <SidebarMenuItem
                    key={idx}
                    id={`${sectionIdx}_${idx}`}
                    minimized={minimized}
                    label={ele.label}
                    to={ele.to}
                    submenuItems={ele.submenuItems}
                    icon={ele.icon}
                    currentMenu={currentMenu}
                    selectMenu={selectMenu}
                  />
                );
              })}
            </>
          </Fragment>
        );
      })}
    </section>
  );
};

export default SidebarMenu;
