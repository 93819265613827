import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

import { AppCard } from '../general';
import { BarData } from '../../models/chart/chart';
import { useEffect, useState } from 'react';

const defaultOptions: ApexOptions = {
  chart: {
    redrawOnParentResize: true,
    toolbar: {
      show: false,
    },
    stacked: true,
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'center',
  },
};

const StackedBarWidget = (props: {
  className?: string;
  title: string;
  chartHeight?: string;
  barData: BarData | undefined;
}): JSX.Element => {
  const { className, title, chartHeight, barData } = props;

  const [series, setSeries] = useState<ApexAxisChartSeries | undefined>([]);
  const [options, setOptions] = useState<ApexOptions | undefined>(defaultOptions);

  useEffect(() => {
    if (!barData) return;

    setOptions({ ...defaultOptions, xaxis: { ...defaultOptions.xaxis, categories: barData?.categories } });
    setSeries(barData?.series);
  }, [barData]);

  return (
    <AppCard className={className} title={title}>
      <Chart height={chartHeight ?? '275px'} options={options} series={series} type="bar" />
    </AppCard>
  );
};

export default StackedBarWidget;
