export interface CreditReportTransaction {
  transactionId: string;
  timestamp: Date;

  // credit
  creditReferenceNumber?: string;
  creditAction?: CreditAction;
  creditType?: CreditType;
  creditReportingCompany?: CreditReportingCompany;

  // borrowers
  borrowers?: Borrower[];

  // loan
  lenderLoanNumber?: string;
  loanType?: LoanType;

  // liability
  totalMonthlyPaymentAmount?: number;
  totalUnpaidBalanceAmount?: number;

  branchId?: string;
  correlationId: string;
}

export interface Borrower {
  lastName?: string;
  addressState?: string;
  creditScores?: CreditScore[];
}

export interface CreditScore {
  score?: number;
  creditBureau?: string;
}

export enum CreditAction {
  Submit = 'Submit',
  Retrieve = 'Retrieve',
  Update = 'Update',
}

export enum CreditType {
  Soft = 'Soft',
  Hard = 'Hard',
}

export enum CreditReportingCompany {
  Xactus = 'Xactus',
  InformativeResearch = 'InformativeResearch',
  FactualData = 'FactualData',
  MeridianLink = 'MeridianLink',
}

export enum LoanType {
  Conventional = 'Conventional',
  FHA = 'FHA',
  USDA = 'USDA',
  VA = 'VA',
}
