import ApiService from './apiService';
import { CreditAccount, CreditSettings } from '../models/client/credit-settings';

class CreditSettingsService extends ApiService {
  private static instance?: CreditSettingsService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!CreditSettingsService.instance) {
      CreditSettingsService.instance = new CreditSettingsService();
    }
    return CreditSettingsService.instance;
  }

  getAll(): Promise<CreditSettings[]> {
    return this.client.get(`/credit-settings`).then((response) => response.data);
  }

  get(id: string): Promise<CreditSettings> {
    return this.client.get(`/credit-settings/${id}`).then((response) => response.data);
  }

  create(settings: CreditSettings): Promise<void> {
    return this.client.post(`/credit-settings`, settings);
  }

  update(settings: CreditSettings): Promise<void> {
    return this.client.put(`credit-settings/${settings.clientId}`, settings);
  }

  delete(id: string): Promise<void> {
    return this.client.delete(`/credit-settings/${id}`);
  }

  getAccounts(): Promise<CreditAccount[]> {
    return this.client.get('/credit-settings/accounts').then((response) => response.data);
  }

  updateAccounts(accounts?: CreditAccount[]) {
    return this.client.put('/credit-settings/accounts', accounts);
  }
}

export default CreditSettingsService;
