import { CreditBureau } from '../credit/credit-report-request';
import { CreditType } from '../credit/credit-report-transaction';
import { EffectivePrice } from './effective-price';

export class CreditSettings {
  clientId?: string;
  isActive: boolean = false;
  priceHistory?: EffectivePrice[] = [];
  accounts?: CreditAccount[] = [];
  costSheet?: CreditCostItem[] = [];

  constructor(clientId: string) {
    this.clientId = clientId;
  }
}

export class CreditAccount {
  creditReportingAgencyId?: string;
  creditType?: CreditType;
  credentials?: KeyValuePair<string>[] = [];
}

export class CreditCostItem {
  creditReportingAgencyId?: string;
  creditType?: CreditType;
  borrowerType?: BorrowerType;
  creditBureaus?: CreditBureau[] = [];
  cost?: number;
}

export class KeyValuePair<T> {
  key?: string;
  value?: T;
}

export enum BorrowerType {
  Individual = 'Individual',
  Joint = 'Joint',
}
