import ApiService from './apiService';
import { CreditReportingAgency, CreditReportingAgencyBasicView } from '../models/credit/credit-reporting-agency';

class ReportAgencyService extends ApiService {
  private static instance?: ReportAgencyService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!ReportAgencyService.instance) {
      ReportAgencyService.instance = new ReportAgencyService();
    }
    return ReportAgencyService.instance;
  }

  getAll(): Promise<CreditReportingAgency[]> {
    return this.client.get(`/credit-reporting-agencies`).then((response) => response.data);
  }

  getAllBasicView(): Promise<CreditReportingAgencyBasicView[]> {
    return this.client.get(`/credit-reporting-agencies/all/basic`).then((response) => response.data);
  }

  get(id: string): Promise<CreditReportingAgency> {
    return this.client.get(`/credit-reporting-agencies/${id}`).then((response) => response.data);
  }

  create(reportingAgency: CreditReportingAgency): Promise<void> {
    return this.client.post(`/credit-reporting-agencies`, reportingAgency);
  }

  update(reportingAgency: CreditReportingAgency): Promise<void> {
    return this.client.put(`credit-reporting-agencies/${reportingAgency.creditReportingAgencyId}`, reportingAgency);
  }

  delete(id: string): Promise<void> {
    return this.client.delete(`/credit-reporting-agencies/${id}`);
  }
}

export default ReportAgencyService;
