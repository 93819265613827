const Overlay = (props: { onClick: () => void }) => {
  const { onClick } = props;

  return (
    <div
      className="bg-[var(--overlay-bg-color)] opacity-20 z-40 fixed top-0 left-0 w-screen h-screen"
      onClick={onClick}
    ></div>
  );
};

export default Overlay;
