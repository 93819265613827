import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { TabPanel, TabView } from 'primereact/tabview';

import useEffectOnce from '../../hooks/useEffectOnce';
import ClientService from '../../services/clientService';
import BranchesByClient from '../branch/BranchesByClient';
import { AppCard, AppPage } from '../../components/general';
import { FormContainer, FormInputText } from '../../components/form';
import { Client } from '../../models/client/client';
import EditCreditSettings from './EditCreditSettings';
import EditDuSettings from './EditDuSettings';
import EditLpaSettings from './EditLpaSettings';
import EditPriceSettings from './EditPriceSettings';

const EditClient = (): JSX.Element => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { clientId } = useParams();

  const clientService = ClientService.getInstance();

  useEffectOnce(() => {
    if (!clientId) {
      reset(new Client());
    } else {
      setLoading(true);
      clientService
        .get(clientId)
        .then((client) => reset(client))
        .catch((error) => showError(error.message))
        .finally(() => setLoading(false));
    }
  });

  const validationSchema = yup.object({
    clientId: yup.string().nullable().required('Client ID is required').trim(),
    clientName: yup.string().nullable().required('Name is required').trim(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<Client>({
    resolver: yupResolver<any>(validationSchema),
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSubmit = handleSubmit((client: Client) => {
    confirmDialog({
      message: `Are you sure you want to save this client: ${client.clientName}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoading(true);
        const addOrUpdate = !clientId ? clientService.create(client) : clientService.update(client);

        setLoading(true);
        addOrUpdate
          .then(() => {
            navigateToClients();
          })
          .catch((error) => {
            showError(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  });

  const navigateToClients = () => {
    navigate('/clients');
  };

  return (
    <AppPage title="Client Details" breadcrumbs="Admin - Client Details">
      <ConfirmDialog />
      <AppCard>
        <TabView>
          <TabPanel header="Basic Details">
            <FormContainer loading={loading} errors={errors} onSubmit={onSubmit} onCancel={navigateToClients}>
              <div className="!grid grid-cols-1">
                <FormInputText
                  control={control}
                  errors={formErrors}
                  field="clientId"
                  label="Client ID"
                  disabled={!!clientId}
                />
                <FormInputText control={control} errors={formErrors} field="clientName" label="Name" />
              </div>
            </FormContainer>
          </TabPanel>
          <TabPanel header="Credit Settings" disabled={!clientId}>
            {!!clientId && <EditCreditSettings clientId={clientId} />}
          </TabPanel>
          <TabPanel header="LPA Settings" disabled={!clientId}>
            {!!clientId && <EditLpaSettings clientId={clientId} />}
          </TabPanel>
          <TabPanel header="DU Settings" disabled={!clientId}>
            {!!clientId && <EditDuSettings clientId={clientId} />}
          </TabPanel>
          <TabPanel header="Price Settings" disabled={!clientId}>
            {!!clientId && <EditPriceSettings clientId={clientId} />}
          </TabPanel>
          <TabPanel header="Branches" disabled={!clientId}>
            {!!clientId && <BranchesByClient clientId={clientId} />}
          </TabPanel>
        </TabView>
      </AppCard>
    </AppPage>
  );
};

export default EditClient;
