import { useState, useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import CreditService from '../../services/creditService';
import CreditSettingsService from '../../services/creditSettingsService';
import ReportAgencyService from '../../services/reportAgencyService';
import { AddDays } from '../../utils/date-helper';
import { EnumToOptions } from '../../utils/form-helper';
import { ToCurrency } from '../../utils/string-helper';
import { AppCard, AppPage } from '../../components/general';
import { Borrower, CreditBureau, CreditReportRequest } from '../../models/credit/credit-report-request';
import {
  FormCalendar,
  FormCheckboxGroup,
  FormContainer,
  FormDropdown,
  FormInputMask,
  FormInputText,
  FormRadioGroup,
} from '../../components/form';
import CreditTestBorrowers from '../../data/credit-test-borrowers.json';
import { CreditAction, CreditType, LoanType } from '../../models/credit/credit-report-transaction';
import { CreditReportResponse, LiabilityAccountStatus } from '../../models/credit/credit-report-response';
import { CreditReportingAgencyBasicView } from '../../models/credit/credit-reporting-agency';
import { State } from '../../models/state';

const includeOptions = [
  {
    label: 'Vendor Report PDF',
    value: 'documents.report.vendor',
  },
  {
    label: 'Client Report PDF',
    value: 'documents.report.client',
  },
];

const CreditEmulator = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<CreditReportResponse>();
  const [cras, setCras] = useState<CreditReportingAgencyBasicView[]>([]);

  const creditService = CreditService.getInstance();
  const creditSettingsService = CreditSettingsService.getInstance();
  const reportAgencyService = ReportAgencyService.getInstance();

  useEffectOnce(() => {
    setLoading(true);
    Promise.all([creditSettingsService.getAccounts(), reportAgencyService.getAllBasicView()])
      .then((response) => {
        const _cras = response[1].filter(
          (cra) => response[0].findIndex((account) => account.creditReportingAgencyId === cra.creditReportingAgencyId) !== -1
        );
        setCras(_cras);
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  });

  const validationSchema = yup.object({
    credit: yup.object({
      creditAction: yup.string().required('Credit Action is a required field'),
      creditType: yup.string().required('Credit Type is a required field'),
      creditBureaus: yup.array().min(1, 'Must select at least one Credit Bureau'),
      creditReferenceNumber: yup.string().when('creditAction', {
        is: (action: CreditAction) => action === CreditAction.Retrieve || action === CreditAction.Update,
        then: (schema) => schema.nullable().required('Credit Ref # is a required field'),
      }),
      creditReportingAgency: yup.string().nullable().required('CRA is a required field'),
    }),
    loan: yup.object({
      lenderLoanNumber: yup.string().required('Loan Number is a required field'),
    }),
    borrowers: yup.array().of(
      yup.object({
        firstName: yup.string().nullable().required('First Name is a required field'),
        lastName: yup.string().nullable().required('Last Name is a required field'),
        ssn: yup.string().nullable().required('SSN is a required field'),
        dateOfBirth: yup.date().nullable().required().max(AddDays(new Date(), -1), 'DOB must be before today'),
        currentAddress: yup.object({
          address1: yup.string().nullable().required('Address is a required field'),
          city: yup.string().nullable().required('City is a required field'),
          state: yup.string().nullable().required('State is a required field'),
          zipCode: yup.string().nullable().required('Zip Code is a required field'),
        }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    getValues,
    setValue,
    watch,
  } = useForm<CreditReportRequest>({
    resolver: yupResolver<any>(validationSchema),
    defaultValues: new CreditReportRequest(),
  });

  const { fields: borrowers, replace } = useFieldArray({
    control,
    name: 'borrowers',
  });

  const creditAction = watch('credit.creditAction');
  const creditReportingAgency = watch('credit.creditReportingAgency');

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSubmit = handleSubmit((request: CreditReportRequest) => {
    request.borrowers?.forEach((borrower, idx) => (borrower.borrowerId = idx.toString()));

    setLoading(true);
    creditService
      .getCreditReport(request)
      .then((response) => {
        response.creditReportingAgency = request.credit?.creditReportingAgency;
        response.borrowerCreditDetails?.forEach((borrower) => {
          const match = request.borrowers?.find((x) => x.borrowerId === borrower.borrowerId);
          borrower.firstName = match?.firstName;
          borrower.lastName = match?.lastName;
        });

        setReport(response);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return (
    <AppPage title="Credit Emulator" breadcrumbs="Home - Credit - Emulator">
      <div className="!grid grid-cols-1 md:grid-cols-12 gap-5">
        <AppCard className="md:col-span-5">
          <FormContainer
            loading={loading}
            errors={errors}
            onSubmit={onSubmit}
            footer={<Button label="Order" type="submit" loading={loading} />}
          >
            <div className="!grid grid-cols-1">
              <FormRadioGroup
                control={control}
                errors={formErrors}
                field="credit.creditType"
                label="Order Option"
                options={EnumToOptions(CreditType)}
              />
              <FormCheckboxGroup
                control={control}
                errors={formErrors}
                field="credit.creditBureaus"
                label="Credit Bureaus"
                options={EnumToOptions(CreditBureau)}
              />
              <FormDropdown
                control={control}
                errors={formErrors}
                field="credit.creditAction"
                label="Credit Action"
                options={EnumToOptions(CreditAction)}
                onChange={async (e) => {
                  setValue('credit.creditAction', e.value);
                  setValue('credit.creditReferenceNumber', undefined);
                }}
              />
              <FormDropdown
                control={control}
                errors={formErrors}
                field="credit.creditReportingAgency"
                label="CRA"
                options={cras}
                optionLabel="creditReportingAgencyName"
                optionValue="creditReportingAgencyId"
                showFilter={true}
                onChange={(e) => {
                  setValue('credit.creditReportingAgency', e.value);
                  setValue('testCase', undefined);
                  replace(e.value ?? [new Borrower()]);
                }}
              />
              <FormDropdown
                control={control}
                field="testCase"
                errors={formErrors}
                label="Test Case"
                options={
                  CreditTestBorrowers.hasOwnProperty(creditReportingAgency!)
                    ? (CreditTestBorrowers as any)[creditReportingAgency!]
                    : []
                }
                onChange={(e) => {
                  replace(e.value ?? [new Borrower()]);
                  setValue('testCase', e.value);
                }}
                showClear={true}
                showFilter={true}
                disabled={!creditReportingAgency}
              />
              <FormInputText
                control={control}
                errors={formErrors}
                field="credit.creditReferenceNumber"
                label="Credit Ref #"
                disabled={creditAction !== CreditAction.Retrieve && creditAction !== CreditAction.Update}
              />
              <FormInputText control={control} errors={formErrors} field="loan.lenderLoanNumber" label="Loan Number" />
              <FormDropdown
                control={control}
                errors={formErrors}
                field="loan.loanType"
                label="Loan Type"
                options={EnumToOptions(LoanType)}
              />
              <div className="pt-3">
                <div className="font-semibold text-gray-800 text-[1.1rem] mb-2">Borrower(s)</div>
                <span></span>
                <div className="!grid grid-cols-1">
                  {borrowers.map((borrower, idx) => (
                    <div key={borrower.id}>
                      <div className="flex flex-row-reverse">
                        <Button
                          className={classNames('w-auto', { hidden: idx === 0 })}
                          type="button"
                          label="Remove borrower"
                          icon="pi pi-minus"
                          onClick={() => {
                            const borrowers = getValues('borrowers') ?? [];
                            borrowers.splice(idx, 1);
                            setValue('borrowers', [...borrowers]);
                          }}
                          severity="danger"
                          text
                          size="small"
                        />
                      </div>
                      <div className="!grid grid-cols-1 md:grid-cols-2 gap-x-3">
                        <FormInputText
                          control={control}
                          errors={formErrors}
                          field={`borrowers.${idx}.firstName`}
                          label="First Name"
                        />
                        <FormInputText
                          control={control}
                          errors={formErrors}
                          field={`borrowers.${idx}.lastName`}
                          label="Last Name"
                        />
                        <FormInputMask
                          control={control}
                          errors={formErrors}
                          field={`borrowers.${idx}.ssn`}
                          label="SSN"
                          mask="999-99-9999"
                        />
                        <FormCalendar
                          control={control}
                          errors={formErrors}
                          field={`borrowers.${idx}.dateOfBirth`}
                          label="Date of Birth"
                          isDateOnly={true}
                        />
                      </div>
                      <FormInputText
                        control={control}
                        errors={formErrors}
                        field={`borrowers.${idx}.currentAddress.address1`}
                        label="Current Address"
                      />
                      <div className="!grid grid-cols-1 md:grid-cols-3 gap-x-3">
                        <FormInputText
                          control={control}
                          errors={formErrors}
                          field={`borrowers.${idx}.currentAddress.city`}
                          label="City"
                        />
                        <FormDropdown
                          control={control}
                          errors={formErrors}
                          field={`borrowers.${idx}.currentAddress.state`}
                          label="State"
                          options={EnumToOptions(State)}
                          showFilter={true}
                        />
                        <FormInputText
                          control={control}
                          errors={formErrors}
                          field={`borrowers.${idx}.currentAddress.zipCode`}
                          label="Zip"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <div className="flex flex-row-reverse">
                    <Button
                      className="w-auto"
                      type="button"
                      label="Add borrower"
                      icon="pi pi-plus"
                      onClick={() => {
                        const borrowers = getValues('borrowers') ?? [];
                        setValue('borrowers', [...borrowers, new Borrower()]);
                      }}
                      outlined
                      size="small"
                    />
                  </div>
                </div>
              </div>
              <FormCheckboxGroup
                control={control}
                errors={formErrors}
                field="includes"
                label="Includes"
                options={includeOptions}
              />
            </div>
          </FormContainer>
        </AppCard>
        {report && (
          <AppCard className="md:col-span-7">
            <div>
              <div className="font-semibold underline text-gray-800 text-[1.1rem] pb-1">Order Summary</div>
              <div>Credit Ref #: {report.creditReferenceNumber}</div>
              <div>Loan Number: {report.lenderLoanNumber}</div>
              <div>CRA: {report.creditReportingAgency}</div>
              <div>CRC: {report.creditReportingCompany}</div>
              <div>Credit Report Cost: {ToCurrency(report.creditReportTotalCost)}</div>
              <div>Report Type: {report.borrowerCreditDetails?.length === 1 ? 'Individual' : 'Joint'}</div>
            </div>
            <div>
              <div className="font-semibold underline text-gray-800 text-[1.1rem] pt-3 pb-1">Borrower(s)</div>
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="text-left font-semibold">Name</th>
                    <th className="text-left font-semibold">Bureau</th>
                    <th className="text-right font-semibold">Score</th>
                    <th className="text-right font-semibold">Percentile</th>
                  </tr>
                </thead>
                <tbody>
                  {report.borrowerCreditDetails?.map((entry, borrowerIdx) => {
                    return (
                      <tr key={`borrower-${borrowerIdx}`}>
                        <td className="align-top">
                          {entry?.firstName} {entry?.lastName}
                        </td>
                        <td>
                          {entry.creditScores?.map((scores, idx) => (
                            <div key={`borrower-${borrowerIdx}-scores-${idx}-bureau`}>{scores.creditBureau}</div>
                          ))}
                        </td>
                        <td>
                          {entry.creditScores?.map((scores, idx) => (
                            <div key={`borrower-${borrowerIdx}-scores-${idx}-score`} className="text-right">
                              {scores.score}
                            </div>
                          ))}
                        </td>
                        <td>
                          {entry.creditScores?.map((scores, idx) => (
                            <div key={`borrower-${borrowerIdx}-scores-${idx}-rank`} className="text-right">
                              {scores.scoreRankPercentile}
                            </div>
                          ))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div>
              <div className="font-semibold underline text-gray-800 text-[1.1rem] pt-3 pb-1">Open Liability Summary</div>
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="text-left font-semibold">Account Type</th>
                    <th className="text-right font-semibold">Count</th>
                    <th className="text-right font-semibold">Total MPA</th>
                    <th className="text-right font-semibold">Total UPB</th>
                  </tr>
                </thead>
                <tbody>
                  {report.liabilityDetails?.totals
                    ?.filter((x) => x.accountStatus === LiabilityAccountStatus.Open)
                    .map((entry, idx) => {
                      return (
                        <tr key={`liabilities-total-${idx}`}>
                          <td>{entry.accountType}</td>
                          <td className="text-right">{entry.count}</td>
                          <td className="text-right">{ToCurrency(entry.totalMonthlyPaymentAmount)}</td>
                          <td className="text-right">{ToCurrency(entry.totalUnpaidBalanceAmount)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div>
              <div className="font-semibold underline text-gray-800 text-[1.1rem] pt-3 pb-1">Open Liability Detail</div>
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="text-left font-semibold">Account Type</th>
                    <th className="text-left font-semibold">Creditor</th>
                    <th className="text-left font-semibold">Type</th>
                    <th className="text-right font-semibold">MPA</th>
                    <th className="text-right font-semibold">UPB</th>
                  </tr>
                </thead>
                <tbody>
                  {report.liabilityDetails?.liabilities
                    ?.filter((x) => x.accountStatus === LiabilityAccountStatus.Open)
                    .map((entry, idx) => {
                      return (
                        <tr key={`liabilities-open-${idx}`}>
                          <td>{entry.accountType}</td>
                          <td>{entry.creditor}</td>
                          <td>{entry.accountType}</td>
                          <td className="text-right">{ToCurrency(entry.monthlyPaymentAmount)}</td>
                          <td className="text-right">{ToCurrency(entry.unpaidBalanceAmount)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div>
              <div className="font-semibold underline text-gray-800 text-[1.1rem] pt-3 pb-1">Document(s)</div>
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="text-left font-semibold">Name</th>
                    <th className="text-left font-semibold">Download</th>
                  </tr>
                </thead>
                <tbody>
                  {report.documents?.map((entry, idx) => {
                    if (entry.documentContentType !== 'Pdf') return <></>;

                    const name =
                      entry.documentName &&
                      includeOptions.find((option) => option.value.includes(entry.documentName!))?.label;
                    return (
                      <tr key={`documents-${idx}`}>
                        <td>{name}</td>
                        <td>
                          <a
                            className="text-blue-600 underline"
                            download={`${name}.pdf`}
                            href={`data:application/pdf;base64,${entry.documentContent}`}
                          >
                            Download
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </AppCard>
        )}
      </div>
    </AppPage>
  );
};

export default CreditEmulator;
