import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { classNames } from 'primereact/utils';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { PickList } from 'primereact/picklist';

import useEffectOnce from '../../hooks/useEffectOnce';
import BranchService from '../../services/branchService';
import ReportAgencyService from '../../services/reportAgencyService';
import { AppPage, AppCard } from '../../components/general';
import { FormContainer, FormInputText } from '../../components/form';
import { CreditReportingAgency } from '../../models/credit/credit-reporting-agency';
import { Branch } from '../../models/branch';

const EditBranch = (): JSX.Element => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [craPickSource, setCraPickSource] = useState<CreditReportingAgency[]>([]);
  const [craPickTarget, setCraPickTarget] = useState<CreditReportingAgency[]>([]);

  const navigate = useNavigate();
  const { clientId, branchId } = useParams();

  const branchService = BranchService.getInstance();
  const reportAgencyService = ReportAgencyService.getInstance();

  useEffectOnce(() => {
    if (!clientId) {
      showError('Missing client id.');
      throw new Error('Missing client id.');
    }

    let apiCalls: Promise<any>[] = [reportAgencyService.getAll()];
    if (!branchId) {
      reset({ clientId: clientId, branchId: '' });
    } else {
      apiCalls.push(branchService.get(clientId, branchId));
    }

    setLoading(true);
    Promise.all(apiCalls)
      .then((results) => {
        if (!!results[1]) {
          reset(results[1]);

          let source: CreditReportingAgency[] = [];
          let target: CreditReportingAgency[] = [];
          const craIds = results[1].creditReportingAgencyIds;
          const existingCras = new Set(craIds);
          results[0].forEach((cra: CreditReportingAgency) => {
            if (existingCras.has(cra.creditReportingAgencyId)) {
              target.push(cra);
            } else {
              source.push(cra);
            }
          });

          setCraPickSource(source.sort((a, b) => a.creditReportingAgencyName!.localeCompare(b.creditReportingAgencyName!)));

          target.sort((a, b) => craIds.indexOf(a.creditReportingAgencyId) - craIds.indexOf(b.creditReportingAgencyId));
          setCraPickTarget(target);
        } else {
          setCraPickSource(results[0]);
        }
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  });

  const validationSchema = yup.object({
    clientId: yup.string().nullable().required('Client is required').trim(),
    branchId: yup.string().nullable().required('Branch ID is required').trim(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<Branch>({
    resolver: yupResolver<any>(validationSchema),
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSubmit = handleSubmit((branch: Branch) => {
    confirmDialog({
      message: `Are you sure you want to save this branch: ${branch.branchId}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const addOrUpdate = !branchId ? branchService.create(branch) : branchService.update(branch);

        setLoading(true);
        addOrUpdate
          .then(() => {
            navigateToClient();
          })
          .catch((error) => {
            showError(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  });

  const navigateToClient = () => {
    navigate(`/clients/${clientId}`);
  };

  return (
    <AppPage title="Branch Details" breadcrumbs="Admin - Branch Details">
      <ConfirmDialog />
      <AppCard>
        <FormContainer loading={loading} errors={errors} onSubmit={onSubmit} onCancel={navigateToClient}>
          <div className="!grid grid-cols-1">
            <FormInputText control={control} errors={formErrors} field="branchId" label="Branch Id" disabled={!!branchId} />
            <div className="field">
              <Controller
                name="creditReportingAgencyIds"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames('font-medium', { 'p-error': fieldState.error })}>
                      CRA(s)
                    </label>
                    <PickList
                      dataKey="creditReportingAgencyId"
                      source={craPickSource}
                      target={craPickTarget}
                      onChange={(e) => {
                        setCraPickSource(e.source);
                        setCraPickTarget(e.target);
                        field.onChange(e.target.map((cra: CreditReportingAgency) => cra.creditReportingAgencyId));
                      }}
                      itemTemplate={(cra: CreditReportingAgency) => cra.creditReportingAgencyName}
                      sourceHeader="Available"
                      targetHeader="Selected"
                      filter
                      filterBy="creditReportingAgencyName"
                      sourceFilterPlaceholder="Search by name"
                      targetFilterPlaceholder="Search by name"
                      sourceStyle={{ height: '24rem' }}
                      targetStyle={{ height: '24rem' }}
                    />
                  </>
                )}
              />
            </div>
          </div>
        </FormContainer>
      </AppCard>
    </AppPage>
  );
};

export default EditBranch;
