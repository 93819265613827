export const AddDays = (date: Date, days: number): Date => {
  const newDate = date.getDate() + days;
  date.setDate(newDate);
  return date;
};

export const GetDaysBetween = (startDate: Date, endDate: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  return Math.round(Math.abs((startDate.valueOf() - endDate.valueOf()) / oneDay));
};
