export class UserAccountDto {
  cognitoId?: string = '';
  email?: string = '';
  clientId?: string = '';
  firstName?: string = '';
  lastName?: string = '';
  updatedDate?: Date | string;
  updatedBy?: string;
}

export class UserAccount extends UserAccountDto {
  clientName?: string;
}
