import { useState, useRef } from 'react';
import { endOfDay } from 'date-fns';

import { Messages } from 'primereact/messages';

import useEffectOnce from '../../hooks/useEffectOnce';
import UnderwriteService from '../../services/underwriteService';
import { AppCard, AppPage } from '../../components/general';
import { DataTable, TableDefinition, DataTableSearchHeader } from '../../components/datatable';
import { UnderwriteTransaction } from '../../models/underwrite/underwrite-transaction';
import CalendarRange, { DefaultRangeSelectType } from '../../components/common/CalendarRange';

const Underwrites = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Array<UnderwriteTransaction>>([]);
  const [search, setSearch] = useState<string>('');

  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), 1);
  const end = endOfDay(new Date(now.getFullYear(), now.getMonth() + 1, 0));
  const [dateRange, setDateRange] = useState<Date[] | undefined>([start, end]);

  const underwriteService = UnderwriteService.getInstance();

  useEffectOnce(() => {
    getUnderwrites(start, end);
  });

  const getUnderwrites = (start: Date, end: Date): void => {
    setLoading(true);
    underwriteService
      .searchTransactions(start, end)
      .then((transactions) => {
        setTransactions(transactions);
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const tableDef: TableDefinition<UnderwriteTransaction> = {
    columns: [
      {
        field: 'timestamp',
        header: 'Timestamp',
        dataType: 'timestamp',
      },
      {
        field: 'lenderLoanNumber',
        header: 'Lender Loan Number',
        dataType: 'text',
      },
      {
        field: 'aus',
        header: 'AUS',
        body: (transaction) => transaction.aus?.toUpperCase(),
        dataType: 'text',
      },
      {
        field: 'loanType',
        header: 'Loan Type',
        dataType: 'text',
      },
      {
        field: 'creditReferenceNumbers',
        header: 'Credit Reference Number',
        body: (transaction) => transaction.creditReferenceNumbers?.join(', '),
        dataType: 'text',
        filter: false,
        sortable: false,
      },
      {
        field: 'responseTransactionId',
        header: 'Transaction Id',
        dataType: 'text',
      },
      {
        field: 'caseIdentifier',
        header: 'Case Identifier',
        dataType: 'text',
      },
      {
        field: 'recommendation',
        header: 'recommendation',
        dataType: 'text',
      },
      {
        field: 'statusCode',
        header: 'Status Code',
        dataType: 'text',
      },
      {
        field: 'representationAndWarrantyRelief.assetEligible',
        header: 'Asset Eligible',
        body: (transaction) => transaction.representationAndWarrantyRelief?.assetEligible,
        dataType: 'text',
      },
      {
        field: 'representationAndWarrantyRelief.collateralEligible',
        header: 'Collateral Eligible',
        body: (transaction) => transaction.representationAndWarrantyRelief?.collateralEligible,
        dataType: 'text',
      },
      {
        field: 'representationAndWarrantyRelief.incomeEligible',
        header: 'Income Eligible',
        body: (transaction) => transaction.representationAndWarrantyRelief?.incomeEligible,
        dataType: 'text',
      },
      {
        field: 'correlationId',
        header: 'Correlation Id',
        dataType: 'text',
      },
    ],
  };

  const headerTemplate = () => {
    return (
      <div className="flex flex-row justify-between items-center">
        <DataTableSearchHeader onSearchChange={setSearch} />
        <div className="p-input-filled">
          <CalendarRange
            rangeSelectOptions={[
              DefaultRangeSelectType.PastWeek,
              DefaultRangeSelectType.Past2Weeks,
              DefaultRangeSelectType.Past30Days,
              DefaultRangeSelectType.CurrentMonth,
            ]}
            value={dateRange}
            onValueChange={(value) => {
              if (!!value && value.length === 2) {
                getUnderwrites(value[0], value[1]);
              }
              setDateRange(value);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <AppPage title="Underwrite" breadcrumbs="Home - Underwrite">
      <AppCard>
        <Messages ref={errors} />
        <DataTable
          loading={loading}
          header={headerTemplate()}
          tableDef={tableDef}
          data={transactions}
          globalFilterValue={search}
        />
      </AppCard>
    </AppPage>
  );
};

export default Underwrites;
