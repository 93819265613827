import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import useEffectOnce from '../../hooks/useEffectOnce';
import ReportAgencyService from '../../services/reportAgencyService';
import { AppPage, AppCard } from '../../components/general';
import { FormContainer, FormDropdown, FormInputText, FormSelectButton } from '../../components/form';
import { CreditReportingAgency, CreditReportingCompany } from '../../models/credit/credit-reporting-agency';
import { EnumToOptions } from '../../utils/form-helper';
import { YesNoOptions } from '../../components/form/FormSelectButton';

const EditCreditReportingAgency = (): JSX.Element => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { creditReportingAgencyId } = useParams();

  const reportAgencytService = ReportAgencyService.getInstance();

  useEffectOnce(() => {
    if (!creditReportingAgencyId) {
      reset(new CreditReportingAgency());
    } else {
      setLoading(true);
      reportAgencytService
        .get(creditReportingAgencyId)
        .then((reportingAgency) => reset(reportingAgency))
        .catch((error) => showError(error.message))
        .finally(() => setLoading(false));
    }
  });

  const validationSchema = yup.object({
    creditReportingAgencyId: yup.string().nullable().required('Agency ID is required').trim(),
    creditReportingAgencyName: yup.string().nullable().required('Agency Name is required').trim(),
    creditReportingCompany: yup.string().nullable().required('CRC is required').trim(),
    meridianLinkBaseUrl: yup
      .string()
      .nullable()
      .when('creditReportingCompany', {
        is: CreditReportingCompany.MeridianLink,
        then: (schema) => schema.nullable().required().trim(),
      }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    watch,
  } = useForm<CreditReportingAgency>({
    resolver: yupResolver<any>(validationSchema),
  });

  const creditReportingCompany = watch('creditReportingCompany');

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSubmit = handleSubmit((reportingAgency: CreditReportingAgency) => {
    const addOrUpdate = !creditReportingAgencyId
      ? reportAgencytService.create(reportingAgency)
      : reportAgencytService.update(reportingAgency);

    setLoading(true);
    addOrUpdate
      .then(() => {
        navigateToAgencies();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const navigateToAgencies = () => {
    navigate('/credit-reporting-agencies');
  };

  return (
    <AppPage title="Credit Reporting Agency Details" breadcrumbs="Admin - Credit Reporting Agency Details">
      <AppCard>
        <FormContainer loading={loading} errors={errors} onSubmit={onSubmit} onCancel={navigateToAgencies}>
          <div className="!grid grid-cols-1">
            <FormInputText
              control={control}
              errors={formErrors}
              field="creditReportingAgencyId"
              label="Agency ID"
              disabled={!!creditReportingAgencyId}
            />
            <FormInputText control={control} errors={formErrors} field="creditReportingAgencyName" label="Agent Name" />
            <FormDropdown
              control={control}
              errors={formErrors}
              field="creditReportingCompany"
              label="Company"
              options={EnumToOptions(CreditReportingCompany)}
            />
            <FormSelectButton
              control={control}
              errors={formErrors}
              field="isActive"
              label="Is Active"
              options={YesNoOptions}
            />
            <div className="!grid grid-cols-2 gap-x-3">
              <FormInputText control={control} errors={formErrors} field="duIdentifier" label="DU Identifier" />
              <FormInputText control={control} errors={formErrors} field="lpaIdentifier" label="LPA Identifier" />
            </div>
            <FormInputText
              control={control}
              errors={formErrors}
              field="meridianLinkBaseUrl"
              label="Meridian Link URL"
              disabled={creditReportingCompany !== CreditReportingCompany.MeridianLink}
            />
          </div>
        </FormContainer>
      </AppCard>
    </AppPage>
  );
};

export default EditCreditReportingAgency;
