import './App.css';

import { Navigate, Route, Routes } from 'react-router-dom';

import AppLayout from './components/layout/AppLayout';
import Dashboard from './pages/Dashboard';
import CreditEmulator from './pages/credit/CreditEmulator';
import CreditAccounts from './pages/credit/CreditAccounts';
import CreditReports from './pages/credit/CreditReports';
import Underwrites from './pages/underwrite/Underwrites';
import Billing from './pages/billing/Billing';
import Clients from './pages/client/Clients';
import EditClient from './pages/client/EditClient';
import EditBranch from './pages/branch/EditBranch';
import UserAccounts from './pages/user-accounts/UserAccounts';
import EditUserAccount from './pages/user-accounts/EditUserAccount';
import CreditReportingAgencies from './pages/credit/CreditReportingAgencies';
import EditCreditReportingAgency from './pages/credit/EditCreditReportingAgency';

import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';

import awsExports from './aws-exports';
import withGroupAuthorizer from './components/auth/withGroupAuthorizer';

Amplify.configure(awsExports);

function App() {
  return (
    <div>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="" element={<Navigate to="/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="credit/emulator" element={<CreditEmulator />} />
          <Route path="credit/accounts" element={<CreditAccounts />} />
          <Route path="credit/transactions" element={<CreditReports />} />
          <Route path="underwrite" element={<Underwrites />} />
          <Route path="billing" element={<Billing />} />
          <Route path="clients">
            <Route index={true} element={withGroupAuthorizer(Clients, ['Admin'])} />
            <Route path="new" element={withGroupAuthorizer(EditClient, ['Admin'])} />
            <Route path=":clientId" element={withGroupAuthorizer(EditClient, ['Admin'])} />
          </Route>
          <Route path="branches">
            <Route path=":clientId" element={withGroupAuthorizer(EditBranch, ['Admin'])} />
            <Route path=":clientId/:branchId" element={withGroupAuthorizer(EditBranch, ['Admin'])} />
          </Route>
          <Route path="user-accounts">
            <Route index={true} element={withGroupAuthorizer(UserAccounts, ['Admin'])} />
            <Route path="new" element={withGroupAuthorizer(EditUserAccount, ['Admin'])} />
            <Route path=":cognitoId" element={withGroupAuthorizer(EditUserAccount, ['Admin'])} />
          </Route>
          <Route path="credit-reporting-agencies">
            <Route index={true} element={withGroupAuthorizer(CreditReportingAgencies, ['Admin'])} />
            <Route path="new" element={withGroupAuthorizer(EditCreditReportingAgency, ['Admin'])} />
            <Route path=":creditReportingAgencyId" element={withGroupAuthorizer(EditCreditReportingAgency, ['Admin'])} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default withAuthenticator(App, {
  loginMechanisms: ['email'],
  hideSignUp: true,
});
