import ApiService from './apiService';
import { LpaSettings } from '../models/client/lpa-settings';

class LpaSettingsService extends ApiService {
  private static instance?: LpaSettingsService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!LpaSettingsService.instance) {
      LpaSettingsService.instance = new LpaSettingsService();
    }
    return LpaSettingsService.instance;
  }

  getAll(): Promise<LpaSettings[]> {
    return this.client.get(`/lpa-settings`).then((response) => response.data);
  }

  get(id: string): Promise<LpaSettings> {
    return this.client.get(`/lpa-settings/${id}`).then((response) => response?.data);
  }

  create(settings: LpaSettings): Promise<void> {
    return this.client.post(`/lpa-settings`, settings);
  }

  update(settings: LpaSettings): Promise<void> {
    return this.client.put(`lpa-settings/${settings.clientId}`, settings);
  }

  delete(id: string): Promise<void> {
    return this.client.delete(`/lpa-settings/${id}`);
  }
}

export default LpaSettingsService;
