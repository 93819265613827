import { Control, Controller, FieldErrors, FieldPath, FieldValues, get } from 'react-hook-form';

import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';

export type FormInputNumberProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  className?: string;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  field: TName;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  mode?: 'decimal' | 'currency';
  currency?: string;
};

const FormInputNumber = <TFieldValues extends FieldValues>({
  ...props
}: FormInputNumberProps<TFieldValues>): JSX.Element => {
  let { className, control, field, errors, label, disabled, placeholder, mode, currency } = props;

  const errorTemplate = () => {
    const error = get(errors, field);
    return error && <small className="p-error">{error?.message as string}</small>;
  };

  return (
    <div className={className ?? 'field'}>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames('font-medium', { 'p-error': fieldState.error })}>
              {label}
            </label>
            <InputNumber
              id={field.name}
              value={field.value ?? null}
              onValueChange={(e) => field.onChange(e.value as any)}
              className={classNames({ 'p-invalid': fieldState.error })}
              disabled={disabled}
              placeholder={placeholder}
              useGrouping={false}
              mode={mode}
              currency={currency}
            />
          </>
        )}
      />
      {errorTemplate()}
    </div>
  );
};

export default FormInputNumber;
