import { Control, Controller, FieldErrors, FieldPath, FieldValues, get } from 'react-hook-form';

import { SelectButton } from 'primereact/selectbutton';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { classNames } from 'primereact/utils';

export type FormSelectButtonProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  className?: string;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  field: TName;
  label: string;
  disabled?: boolean;
  options: SelectItemOptionsType | undefined;
  optionLabel?: string;
  optionValue?: string;
};

export const YesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const FormSelectButton = <TFieldValues extends FieldValues>({
  ...props
}: FormSelectButtonProps<TFieldValues>): JSX.Element => {
  let { className, control, field, errors, label, disabled, options, optionLabel, optionValue } = props;

  const errorTemplate = () => {
    const error = get(errors, field);
    return error && <small className="p-error">{error?.message as string}</small>;
  };

  return (
    <div className={className ?? 'field'}>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <div className="flex justify-content-between align-items-center">
            <label htmlFor={field.name} className={classNames('font-medium', { 'p-error': fieldState.error })}>
              {label}
            </label>
            <SelectButton
              value={field.value ?? ''}
              options={options}
              optionLabel={optionLabel}
              optionValue={optionValue}
              onChange={(e) => field.onChange(e.value)}
              className={classNames({ 'p-invalid': fieldState.error })}
              disabled={disabled}
              allowEmpty={false}
            />
          </div>
        )}
      />
      {errorTemplate()}
    </div>
  );
};

export default FormSelectButton;
