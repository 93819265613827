export const CapitalizeFirstLetter = (string: string): string => {
  if (string.length === 0) return string;

  if (string.length === 1) return string.toUpperCase();

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ToCurrency = (number?: number): string | undefined => {
  return number?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};
