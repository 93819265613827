import { Control, Controller, FieldErrors, FieldPath, FieldValues, get } from 'react-hook-form';

import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';

export type FormPasswordProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  className?: string;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  field: TName;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  feedback?: boolean;
  toggleMask?: boolean;
};

const FormPassword = <TFieldValues extends FieldValues>({ ...props }: FormPasswordProps<TFieldValues>): JSX.Element => {
  let { className, control, field, errors, label, disabled, placeholder, feedback, toggleMask } = props;

  const errorTemplate = () => {
    const error = get(errors, field);
    return error && <small className="p-error">{error?.message as string}</small>;
  };

  return (
    <div className={className ?? 'field'}>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames('font-medium', { 'p-error': fieldState.error, disabled })}>
              {label}
            </label>
            <Password
              id={field.name}
              value={field.value ?? ''}
              onChange={(e) => (!!e.target.value ? field.onChange(e.target.value as any) : field.onChange(null as any))}
              className={classNames({ 'p-invalid': fieldState.error, disabled })}
              disabled={disabled}
              placeholder={placeholder}
              autoComplete="new-password"
              feedback={feedback ?? false}
              toggleMask={toggleMask ?? true}
            />
          </>
        )}
      />
      {errorTemplate()}
    </div>
  );
};

export default FormPassword;
