import { useRef, useState } from 'react';
import { endOfDay } from 'date-fns';

import { Messages } from 'primereact/messages';

import useEffectOnce from '../hooks/useEffectOnce';
import CreditService from '../services/creditService';
import UnderwriteService from '../services/underwriteService';
import { GetCountByDay, GetCountByGroupByDay } from '../utils/chart-helper';
import { GetAverage } from '../utils/math-calculations';
import { GetDaysBetween } from '../utils/date-helper';
import { AppPage } from '../components/general';
import { DataWidget, LineChartWidget, StackedBarWidget, PieChartWidget } from '../components/widget';
import { BarData, BarSeries, ChartSeries, PieSeries } from '../models/chart/chart';
import { CreditReportTransaction } from '../models/credit/credit-report-transaction';
import CalendarRange, { DefaultRangeSelectType } from '../components/common/CalendarRange';

interface FlattenedTransaction {
  loanType: string;
  score: number | undefined;
  creditBureau: string;
  state: string;
}

const Dashboard = (): JSX.Element => {
  const errors = useRef<any>(null);
  const [totalCreditTransactions, setTotalCreditTransactions] = useState<number>(0);
  const [dailyAverage, setDailyAverage] = useState<number>(0);
  const [averageCreditScore, setAverageCreditScore] = useState<number>(0);
  const [creditsByDay, setCreditsByDay] = useState<ChartSeries[] | undefined>();
  const [creditsByAction, setCreditsByAction] = useState<ChartSeries[] | undefined>();
  const [avgScoreByLoanType, setAvgScoreByLoanType] = useState<BarData | undefined>();
  const [creditsByOrderType, setCreditsByOrderType] = useState<PieSeries | undefined>();
  const [creditsByCreditType, setCreditsByCreditType] = useState<PieSeries | undefined>();

  const [underwritesByDay, setUnderwritesByDay] = useState<ChartSeries[] | undefined>();
  const [underwritesByAus, setUnderwritesByAus] = useState<ChartSeries[] | undefined>();

  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), 1);
  const end = endOfDay(new Date(now.getFullYear(), now.getMonth() + 1, 0));
  const [dateRange, setDateRange] = useState<Date[] | undefined>([start, end]);

  const creditService = CreditService.getInstance();
  const underwriteService = UnderwriteService.getInstance();

  useEffectOnce(() => {
    getCreditTransactions(start, end);
    getUnderwriteTransactions(start, end);
  });

  const getCreditTransactions = (start: Date, end: Date): void => {
    creditService
      .searchTransactions(start, end)
      .then((transactions) => {
        if (!transactions) {
          return;
        }

        setTotalCreditTransactions(transactions.length);
        setDailyAverage(Math.round((transactions.length / GetDaysBetween(start, end)) * 100) / 100);

        const creditScores = transactions.flatMap(
          (t) => t.borrowers?.flatMap((b) => b.creditScores?.flatMap((c) => c.score ?? []) ?? []) ?? []
        );
        setAverageCreditScore(Math.round(GetAverage(creditScores)));

        const countByDay = GetCountByDay(transactions, 'timestamp');
        setCreditsByDay([{ name: 'Count', data: countByDay }]);

        const countByAction = GetCountByGroupByDay(transactions, 'timestamp', 'creditAction');
        setCreditsByAction(countByAction);

        const flattened = transactions.flatMap((t) => {
          const scores = t.borrowers?.flatMap((b) =>
            b.creditScores?.flatMap((s) => ({ state: b.addressState, score: s.score, creditBureau: s.creditBureau }))
          );
          return (
            scores?.flatMap((s) => ({
              loanType: t.loanType ?? 'Unknown',
              score: s?.score,
              creditBureau: s?.creditBureau ?? 'Unknown',
              state: s?.state ?? 'Unknown',
            })) ?? []
          );
        });

        setAvgScoreByLoanTypeFromTransactions(flattened);

        setCountsByOrderTypeFromTransaction(transactions);
        setCountsByCreditTypeFromTransaction(transactions);
      })
      .catch((error) => showError(error.message));
  };

  const getUnderwriteTransactions = (start: Date, end: Date) => {
    underwriteService
      .searchTransactions(start, end)
      .then((transactions) => {
        if (!transactions) {
          return;
        }

        const underwriteTransactions = transactions.filter((t) => !!t.caseIdentifier);

        const countByDay = GetCountByDay(underwriteTransactions, 'timestamp');
        setUnderwritesByDay([{ name: 'Count', data: countByDay }]);

        const countByAus = GetCountByGroupByDay(underwriteTransactions, 'timestamp', 'aus', { Lpa: 'LPA', Du: 'DU' });
        setUnderwritesByAus(countByAus);
      })
      .catch((error) => showError(error.message));
  };

  const setAvgScoreByLoanTypeFromTransactions = (flattened: FlattenedTransaction[]) => {
    const loanTypes = new Set(flattened.map((f) => f.loanType.toString()).sort());
    const bureaus = new Set(flattened.map((f) => f.creditBureau).sort());

    let series: BarSeries[] = [];
    bureaus.forEach((b) => {
      let data: number[] = [];
      loanTypes.forEach((l) => {
        const filtered = flattened
          .filter((f) => f.loanType === l && f.creditBureau === b && !!f.score)
          .map((f) => f.score ?? 0);
        data.push(Math.round(GetAverage(filtered)));
      });

      const bureaShortName = {
        Unknown: 'Unknown',
        Experian: 'EXP',
        TransUnion: 'TRU',
        Equifax: 'EQX',
      }[b];
      series.push({ name: bureaShortName, data: data });
    });

    setAvgScoreByLoanType({
      categories: Array.from(loanTypes),
      series: series,
    });
  };

  const setCountsByOrderTypeFromTransaction = (transactions: CreditReportTransaction[]) => {
    const groups = transactions
      .filter((t) => !!t)
      .reduce((groups: Record<string, number>, value) => {
        const borrowerType = value.borrowers?.length === 1 ? 'Individual' : 'Joint';
        if (!groups[borrowerType]) {
          groups[borrowerType] = 1;
        } else {
          groups[borrowerType]++;
        }
        return groups;
      }, {});

    setCreditsByOrderType({ labels: Object.keys(groups), data: Object.values(groups) });
  };

  const setCountsByCreditTypeFromTransaction = (transactions: CreditReportTransaction[]) => {
    const groups = transactions
      .filter((t) => !!t)
      .reduce((groups: Record<string, number>, value) => {
        if (!value.creditType) return groups;

        if (!groups[value.creditType]) {
          groups[value.creditType] = 1;
        } else {
          groups[value.creditType]++;
        }
        return groups;
      }, {});

    setCreditsByCreditType({ labels: Object.keys(groups), data: Object.values(groups) });
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  return (
    <AppPage
      title="Dashboard"
      breadcrumbs="Home - Dashboard"
      headerEnd={
        <>
          <CalendarRange
            rangeSelectOptions={[
              DefaultRangeSelectType.PastWeek,
              DefaultRangeSelectType.Past2Weeks,
              DefaultRangeSelectType.Past30Days,
              DefaultRangeSelectType.CurrentMonth,
            ]}
            value={dateRange}
            onValueChange={(value) => {
              if (!!value && value.length === 2) {
                getCreditTransactions(value[0], value[1]);
                getUnderwriteTransactions(value[0], value[1]);
              }
              setDateRange(value);
            }}
          />
        </>
      }
    >
      <Messages ref={errors} />
      <div className="!grid gap-5">
        <div className="!grid grid-cols-1 lg:grid-cols-3 gap-5">
          <DataWidget
            cardClassName="!bg-[#d1f0fd]"
            statistic={totalCreditTransactions.toString()}
            description="Total Credit Reports"
          />
          <DataWidget cardClassName="!bg-[#eee2ff]" statistic={dailyAverage.toString()} description="Daily Average #" />
          <DataWidget
            cardClassName="!bg-[#d1f0fd]"
            statistic={averageCreditScore.toString()}
            description="Average Credit Score"
          />
        </div>
        <div className="!grid grid-cols-1 lg:grid-cols-2 gap-5">
          <LineChartWidget title="Credit Reports Run" chartSeries={creditsByDay} />
          <LineChartWidget title="Credit Reports By Action" chartSeries={creditsByAction} />
          <LineChartWidget title="Total Underwrites" chartSeries={underwritesByDay} />
          <LineChartWidget title="Underwrites by Agency" chartSeries={underwritesByAus} />
          <PieChartWidget title="Order Types" pieSeries={creditsByOrderType} />
          <PieChartWidget title="Credit Types" pieSeries={creditsByCreditType} />
          <StackedBarWidget title="Average Score by LoanType/Bureau" barData={avgScoreByLoanType} />
        </div>
      </div>
    </AppPage>
  );
};

export default Dashboard;
