import { SelectItem } from 'primereact/selectitem';

export const EnumToOptions = (enums: Record<string, string>): SelectItem[] => {
  return Object.entries(enums).map((entry) => {
    return {
      label: entry[1],
      value: entry[0],
    };
  });
};
