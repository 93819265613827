import ApiService from './apiService';
import { Branch } from '../models/branch';

class BranchService extends ApiService {
  private static instance?: BranchService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!BranchService.instance) {
      BranchService.instance = new BranchService();
    }
    return BranchService.instance;
  }

  getAll(): Promise<Branch[]> {
    return this.client.get(`/branches`).then((response) => response.data);
  }

  getByClient(clientId: string): Promise<Branch[]> {
    return this.client.get(`/clients/${clientId}/branches`).then((response) => response.data);
  }

  get(clientId: string, branchId: string): Promise<Branch> {
    return this.client.get(`/clients/${clientId}/branches/${branchId}`).then((response) => response.data);
  }

  create(branch: Branch): Promise<void> {
    return this.client.post(`branches`, branch);
  }

  update(branch: Branch): Promise<void> {
    return this.client.put(`clients/${branch.clientId}/branches/${branch.branchId}`, branch);
  }

  delete(branch: Branch): Promise<void> {
    return this.client.delete(`/clients/${branch.clientId}/branches/${branch.branchId}`);
  }
}

export default BranchService;
