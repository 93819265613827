import { Control, Controller, FieldErrors, FieldPath, FieldValues, get } from 'react-hook-form';

import { Checkbox } from 'primereact/checkbox';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { classNames } from 'primereact/utils';

export type FormCheckboxGroupProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  className?: string;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  field: TName;
  label: string;
  disabled?: boolean;
  options: SelectItemOptionsType | undefined;
  optionLabel?: string;
  optionValue?: string;
};

const FormCheckboxGroup = <TFieldValues extends FieldValues>({
  ...props
}: FormCheckboxGroupProps<TFieldValues>): JSX.Element => {
  let { className, control, field, errors, label, disabled, options, optionLabel, optionValue } = props;

  const errorTemplate = () => {
    const error = get(errors, field);
    return error && <small className="p-error">{error?.message as string}</small>;
  };

  return (
    <div className={className ?? 'field'}>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames('font-medium', { 'p-error': fieldState.error, disabled })}>
              {label}
            </label>
            <div className="flex">
              {options?.map((option, idx) => {
                return (
                  <div key={`option-${idx}`} className={classNames('flex align-items-center', { 'ml-2': idx !== 0 })}>
                    <Checkbox
                      id={field.name}
                      name={!!optionLabel ? option[optionLabel] : option['label']}
                      value={!!optionValue ? option[optionValue] : option['value']}
                      onChange={(e) => {
                        let values = [...field.value];

                        if (e.checked) {
                          values.push(e.value);
                        } else {
                          values.splice(values.indexOf(e.value), 1);
                        }

                        field.onChange(values as any);
                      }}
                      checked={field.value.includes(!!optionValue ? option[optionValue] : option['value'])}
                    />
                    <label htmlFor={field.name} className="ml-2">
                      {!!optionLabel ? option[optionLabel] : option['label']}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        )}
      />
      {errorTemplate()}
    </div>
  );
};

export default FormCheckboxGroup;
