import { useState, useEffect } from 'react';

import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { ColumnBodyOptions } from 'primereact/column';
import { DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';

import { DataTable, TableDefinition } from '../../components/datatable';
import EditCreditAccount from './EditCreditReportAccount';
import { CreditAccount } from '../../models/client/credit-settings';

const CreditReportAccounts = (props: {
  className?: string;
  loading?: boolean;
  includeHeader?: boolean;
  accounts?: CreditAccount[];
  onAccountChange: (accounts?: CreditAccount[]) => void;
  size?: 'small' | 'normal' | 'large';
}): JSX.Element => {
  const { className, loading, includeHeader, onAccountChange, size } = props;

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<CreditAccount[] | undefined>(props.accounts);
  const [editEntry, setEditEntry] = useState<CreditAccount>(new CreditAccount());
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

  useEffect(() => {
    setAccounts(props.accounts);
  }, [props.accounts]);

  const onAdd = () => {
    setEditEntry(new CreditAccount());
    setShowDialog(true);
  };

  const onEdit = (account: CreditAccount) => {
    setEditEntry(account);
    setShowDialog(true);
  };

  const onDelete = (account: CreditAccount) => {
    confirmDialog({
      message: `Are you sure you want to delete this account: ${account.creditReportingAgencyId} - ${account.creditType}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let updated = [...accounts!];
        const index = accounts!.findIndex(
          (x) => x.creditReportingAgencyId === account.creditReportingAgencyId && x.creditType === account.creditType
        );
        updated.splice(index, 1);
        onAccountChange(updated);
      },
    });
  };

  const actionBodyTemplate = (account: CreditAccount, options: ColumnBodyOptions) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => onEdit(account)}
          tooltip="Edit Account"
          tooltipOptions={{ position: 'left' }}
          type="button"
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() => onDelete(account)}
          tooltip="Delete Account"
          tooltipOptions={{ position: 'left' }}
          severity="danger"
          type="button"
        />
      </div>
    );
  };

  const rowExpansionTemplate = (account: CreditAccount) => {
    return (
      <table className="ml-20 min-w-[200px]">
        <thead>
          <tr className="!grid grid-cols-2">
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {account.credentials &&
            account.credentials.map((entry, idx) => {
              const value = entry.key === 'password' ? <td>****</td> : <td>{entry.value}</td>;
              return (
                <tr key={`cred-${idx}`} className="!grid grid-cols-2">
                  <td>{entry.key}</td>
                  {value}
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  const tableDef: TableDefinition<CreditAccount> = {
    columns: [
      {
        field: 'creditReportingAgencyId',
        header: 'CRA ID',
        dataType: 'text',
        sortable: false,
        filter: false,
      },
      {
        field: 'creditType',
        header: 'Credit Type',
        dataType: 'text',
        sortable: false,
        filter: false,
      },
      {
        header: 'Actions',
        body: actionBodyTemplate,
        dataType: 'none',
        sortable: false,
        filter: false,
      },
    ],
  };

  const headerTemplate = () => {
    return (
      <div className="flex flex-col lg:flex-row justify-content-between align-items-center">
        <div className="font-semibold text-base">Accounts</div>
        <div>
          <Button icon="pi pi-plus" label="Add New" onClick={onAdd} type="button" outlined size="small" />
        </div>
      </div>
    );
  };

  return (
    <>
      <DataTable
        loading={loading}
        header={includeHeader ? headerTemplate() : undefined}
        headerClassName="mb-2"
        tableDef={tableDef}
        data={accounts ?? []}
        paginator={false}
        className={className}
        isExpansion={true}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={(e) => setExpandedRows(e.data)}
        size={size ?? 'small'}
      />
      {showDialog && (
        <EditCreditAccount
          entry={editEntry}
          onSave={(entry) => {
            const updated = [...accounts!];
            const idx = updated.findIndex(
              (x) => x.creditReportingAgencyId === entry.creditReportingAgencyId && x.creditType === entry.creditType
            );
            if (idx === -1) {
              updated.push(entry);
            } else {
              updated[idx] = entry;
            }
            onAccountChange(updated);
            setShowDialog(false);
          }}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </>
  );
};

export default CreditReportAccounts;
