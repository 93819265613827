import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Messages } from 'primereact/messages';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import BranchService from '../../services/branchService';
import { DataTable, TableDefinition, DataTableSearchCreateHeader } from '../../components/datatable';
import { Branch } from '../../models/branch';

const BranchesByClient = (props: { clientId: string }): JSX.Element => {
  const { clientId } = props;
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  const branchService = BranchService.getInstance();

  useEffectOnce(() => {
    getBranches();
  });

  const getBranches = (): void => {
    setLoading(true);
    branchService
      .getByClient(clientId)
      .then((branches) => {
        setBranches(branches);
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onAdd = () => {
    navigate(`/branches/${clientId}`);
  };

  const onEdit = (branch: Branch) => {
    navigate(`/branches/${branch.clientId}/${branch.branchId}`);
  };

  const onDelete = (branch: Branch) => {
    confirmDialog({
      message: `Are you sure you want to delete this branch: ${branch.clientId} - ${branch.branchId}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoading(true);
        branchService
          .delete(branch)
          .then(() => getBranches())
          .catch((error) => showError(error.message))
          .finally(() => setLoading(false));
      },
    });
  };

  const actionBodyTemplate = (branch: Branch) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => onEdit(branch)}
          tooltip="Edit Client"
          tooltipOptions={{ position: 'left' }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() => onDelete(branch)}
          tooltip="Delete Client"
          tooltipOptions={{ position: 'left' }}
          severity="danger"
        />
      </div>
    );
  };

  const tableDef: TableDefinition<Branch> = {
    columns: [
      {
        field: 'branchId',
        header: 'Branch Id',
        dataType: 'text',
      },
      {
        field: 'creditReportingAgencyIds',
        header: 'CRA(s)',
        body: (branch) => branch.creditReportingAgencyIds?.join(', '),
        dataType: 'text',
      },
      {
        field: 'updatedDate',
        header: 'Updated Date',
        dataType: 'date',
      },
      {
        field: 'updatedBy',
        header: 'Updated By',
        dataType: 'text',
      },
      {
        header: 'Actions',
        body: actionBodyTemplate,
        dataType: 'none',
        sortable: false,
        filter: false,
      },
    ],
  };

  return (
    <div>
      <Messages ref={errors} />
      <DataTable
        loading={loading}
        header={<DataTableSearchCreateHeader onCreate={onAdd} onSearchChange={setSearch} />}
        tableDef={tableDef}
        data={branches}
        globalFilterValue={search}
      />
    </div>
  );
};

export default BranchesByClient;
