import { AddDays } from '../../utils/date-helper';
import { CreditAction, CreditType, LoanType } from './credit-report-transaction';

export enum CreditBureau {
  Experian = 'Experian',
  Equifax = 'Equifax',
  TransUnion = 'TransUnion',
}

export class CreditReportRequest {
  credit?: Credit = new Credit();
  loan?: Loan = new Loan();
  borrowers?: Borrower[] = [new Borrower()];
  includes?: string[] = [];

  testCase?: Borrower[];
}

export class Credit {
  creditAction?: CreditAction = CreditAction.Submit;
  creditType?: CreditType = CreditType.Soft;
  creditBureaus?: CreditBureau[] = [CreditBureau.Experian, CreditBureau.Equifax, CreditBureau.TransUnion];
  creditReferenceNumber?: string;
  creditReportingAgency?: string;
}

export class Loan {
  lenderLoanNumber?: string;
  loanType?: LoanType;
}

export class Borrower {
  borrowerId?: string;
  firstName?: string;
  lastName?: string;
  ssn?: string;
  dateOfBirth?: Date = AddDays(new Date(), -1);
  currentAddress?: Address;
}

export class Address {
  address1?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}
