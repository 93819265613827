import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormInputNumber, FormCalendar } from '../../components/form';
import { EffectivePrice } from '../../models/client/effective-price';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export type EffectivePriceEntry = {
  effectivePrice: EffectivePrice;
  index: number;
};

const EditEffectivePrice = (props: {
  entry: EffectivePriceEntry;
  onSave: (entry: EffectivePriceEntry) => void;
  onCancel: () => void;
}): JSX.Element => {
  const [entry, setEntry] = useState<EffectivePriceEntry>(props.entry);

  useEffect(() => {
    setEntry(props.entry);
    reset(props.entry.effectivePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entry]);

  const validationSchema = yup.object({
    effectiveDate: yup.string().nullable().required('Effective date is required').trim(),
    price: yup.string().nullable().required('Price is required').trim(),
  });

  const {
    control,
    formState: { errors: formErrors },
    reset,
    getValues,
  } = useForm<EffectivePrice>({
    resolver: yupResolver<any>(validationSchema),
  });

  return (
    <Dialog
      visible={true}
      style={{ width: '50vw' }}
      onHide={() => {}}
      footer={() => (
        <div className="text-right">
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-secondary p-button mr-3"
            onClick={props.onCancel}
            type="button"
          />
          <Button
            label="Save"
            onClick={() => props.onSave({ effectivePrice: getValues(), index: entry.index })}
            icon="pi pi-check"
            className="p-button-primary p-button"
            type="button"
          />
        </div>
      )}
      closable={false}
    >
      <div className="grow p-fluid p-input-filled">
        <FormCalendar control={control} errors={formErrors} field="effectiveDate" label="Effective Date" showTime={true} />
        <FormInputNumber control={control} errors={formErrors} field="price" label="Price" mode="currency" currency="USD" />
      </div>
    </Dialog>
  );
};

export default EditEffectivePrice;
