import { useState, useRef } from 'react';

import { Messages } from 'primereact/messages';
import { Dropdown } from 'primereact/dropdown';
import { TabPanel, TabView } from 'primereact/tabview';

import BillingService from '../../services/billingService';

import useEffectOnce from '../../hooks/useEffectOnce';
import { ToCurrency } from '../../utils/string-helper';
import { AppCard, AppPage } from '../../components/general';
import BillingByLoan from './BillingByLoan';
import BillingByService from './BillingByService';
import { BillingItem } from '../../models/billing/billing-item';
import { BillingTerm } from '../../models/billing/billing-term';

const Billing = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<BillingItem[]>([]);
  const [total, setTotal] = useState<number>(0);

  const [terms, setTerms] = useState<BillingTerm[]>();
  const [term, setTerm] = useState<BillingTerm>();

  const billingService = BillingService.getInstance();

  useEffectOnce(() => {
    const terms = getTerms();
    setTerms(terms);
    setTerm(terms[0]);
    getItemization(terms[0]);
  });

  const getTerms = () => {
    const startDate = new Date('2023-09-01T08:00:00Z');
    const endDate = new Date();
    const terms: BillingTerm[] = [];

    let currentDate = new Date(endDate);
    while (currentDate >= startDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();

      // First day of the month
      const firstDay = new Date(Date.UTC(year, month, 1, 8, 0, 0, 0));

      // Last day of the month
      const lastDay = new Date(Date.UTC(year, month + 1, 1, 7, 59, 59, 999));

      terms.push({
        term: `${currentDate.toLocaleString('default', { month: 'long' })} ${year}`,
        startDate: firstDay,
        endDate: lastDay,
        period: `${firstDay.toDateString()} - ${lastDay.toDateString()}`,
      });

      // Move to the previous month
      currentDate.setMonth(month - 1);
    }

    return terms;
  };

  const getItemization = (term: BillingTerm): void => {
    setLoading(true);
    billingService
      .getItemization(term.startDate, term.endDate)
      .then((results) => {
        setItems(results);
        setTotal(results?.flatMap((i) => i.amount ?? []).reduce((a, b) => a + b, 0) ?? 0);
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.aoa_to_sheet([
        ['Billing Summary'],
        [`Billing Period: ${term?.period}`],
        [`Total: ${ToCurrency(total)}`],
      ]);

      let data: (string | undefined)[][] = [['Loan Number', 'Service', 'Date', 'Amount']];
      data = data.concat(
        items.map((item) => [
          item.loanNumber,
          item.service,
          item.timestamp!.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          ToCurrency(item.amount),
        ])
      );

      xlsx.utils.sheet_add_aoa(worksheet, data, { origin: { r: 4, c: 0 } });

      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, 'data');
      xlsx.writeFile(workbook, 'Billing.xlsx');
    });
  };

  return (
    <AppPage title="Billing" breadcrumbs="Home - Billing">
      <div className="!grid gap-5">
        <AppCard>
          <Messages ref={errors} />
          <div className="flex flex-row justify-content-between items-center">
            <div className="font-semibold text-gray-800 text-[1.3rem]">Billing Summary</div>
            <div className="p-input-filled">
              <label className="font-semibold text-gray-800 text-lg pr-2">Billing Period:</label>
              <Dropdown
                id="BillingPeriod"
                value={term}
                options={terms}
                optionLabel="term"
                onChange={(e) => {
                  setTerm(e.value);
                  getItemization(e.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-column gap-1 font-semibold">
            <div>
              Billing Period: <span className="font-normal">{term?.period}</span>
            </div>
            <div>
              Total: <span className="font-normal">{ToCurrency(total)}</span>
            </div>
            <div>
              Export Data:{' '}
              <span className="font-normal text-blue-600 underline cursor-pointer" onClick={exportExcel}>
                Download
              </span>
            </div>
          </div>
        </AppCard>
        <AppCard>
          <TabView>
            <TabPanel header="Charges by Loan">
              <BillingByLoan loading={loading} items={items} />
            </TabPanel>
            <TabPanel header="Charges by Service">
              <BillingByService loading={loading} items={items} />
            </TabPanel>
          </TabView>
        </AppCard>
      </div>
    </AppPage>
  );
};

export default Billing;
