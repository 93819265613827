import { ReactNode } from 'react';

import { classNames } from 'primereact/utils';

const AppCard = (props: { className?: string; title?: string; subtitle?: string; children: ReactNode }): JSX.Element => {
  const { className, title, subtitle, children } = props;

  return (
    <div className={classNames('shadow-[0_0_20px_0_rgba(76,87,125,0.02)] rounded-2xl px-5 bg-[#FFF]', className)}>
      {title && (
        <div className="flex flex-col pt-5">
          <div className="font-semibold text-gray-800 text-[1.3rem]">{title}</div>
          {subtitle && <div className="font-semibold text-gray-500 text-base">{subtitle}</div>}
        </div>
      )}
      <div className="py-4">{children}</div>
    </div>
  );
};

export default AppCard;
