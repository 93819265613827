import { HTMLInputTypeAttribute } from 'react';
import { Control, Controller, FieldErrors, FieldPath, FieldValues, get } from 'react-hook-form';

import { InputMask } from 'primereact/inputmask';
import { classNames } from 'primereact/utils';

export type FormInputMaskProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  className?: string;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  field: TName;
  mask: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  type?: HTMLInputTypeAttribute | undefined;
};

const FormInputMask = <TFieldValues extends FieldValues>({ ...props }: FormInputMaskProps<TFieldValues>): JSX.Element => {
  let { className, control, field, errors, label, mask, disabled, placeholder, type } = props;

  const errorTemplate = () => {
    const error = get(errors, field);
    return error && <small className="p-error">{error?.message as string}</small>;
  };

  return (
    <div className={className ?? 'field'}>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames('font-medium', { 'p-error': fieldState.error, disabled })}>
              {label}
            </label>
            <InputMask
              id={field.name}
              className={classNames({ 'p-invalid': fieldState.error, disabled })}
              value={field.value ?? ''}
              onChange={(e) => (!!e.target.value ? field.onChange(e.target.value as any) : field.onChange(null as any))}
              mask={mask}
              disabled={disabled}
              placeholder={placeholder}
              type={type}
              autoComplete={type === 'password' ? 'new-password' : undefined}
              unmask={true}
            />
          </>
        )}
      />
      {errorTemplate()}
    </div>
  );
};

export default FormInputMask;
