import { FilterOperator, FilterMatchMode } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { ColumnFilterMatchModeOptions, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { DataTableFilterMetaData, DataTableOperatorFilterMetaData } from 'primereact/datatable';
import { ReactNode } from 'react';
import { DataTableColType } from '../components/datatable/DataTable';
import { Dropdown } from 'primereact/dropdown';
import { YesNoOptions } from '../components/form/FormSelectButton';

export const initFilter: {
  [key in DataTableColType]: DataTableFilterMetaData | DataTableOperatorFilterMetaData | undefined;
} = {
  text: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
  numeric: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  currency: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  date: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }] },
  timestamp: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }] },
  boolean: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  none: undefined,
};

export const filterOptions: { [key in DataTableColType]: ColumnFilterMatchModeOptions[] } = {
  text: [
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Does not contain', value: FilterMatchMode.NOT_CONTAINS },
  ],
  numeric: [
    { label: 'Equals', value: FilterMatchMode.EQUALS },
    { label: 'Does not equal', value: FilterMatchMode.NOT_EQUALS },
  ],
  currency: [
    { label: 'Equals', value: FilterMatchMode.EQUALS },
    { label: 'Does not equal', value: FilterMatchMode.NOT_EQUALS },
  ],
  date: [
    { label: 'Date is equal to', value: FilterMatchMode.DATE_IS },
    { label: 'Date is before', value: FilterMatchMode.DATE_BEFORE },
    { label: 'Date is after', value: FilterMatchMode.DATE_AFTER },
  ],
  timestamp: [
    { label: 'Date is equal to', value: FilterMatchMode.DATE_IS },
    { label: 'Date is before', value: FilterMatchMode.DATE_BEFORE },
    { label: 'Date is after', value: FilterMatchMode.DATE_AFTER },
  ],
  boolean: [
    { label: 'Equals', value: FilterMatchMode.EQUALS },
    { label: 'Does not equal', value: FilterMatchMode.NOT_EQUALS },
  ],
  none: [],
};

export const filterTemplates: {
  [key in DataTableColType]: ReactNode | ((options: ColumnFilterElementTemplateOptions) => ReactNode);
} = {
  text: undefined,
  numeric: undefined,
  currency: undefined,
  date: (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  },
  timestamp: (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        showTime
        hourFormat="12"
        placeholder="mm/dd/yyyy hh:mm"
      />
    );
  },
  boolean: (options) => {
    return (
      <Dropdown
        value={options.value}
        options={YesNoOptions}
        onChange={(e) => options.filterCallback(e.value, options.index)}
      />
    );
  },
  none: undefined,
};
