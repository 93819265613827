import { ReactNode } from 'react';

import { AppCard } from '../general';

const DataWidget = (props: {
  className?: string;
  cardClassName?: string;
  statistic: string;
  description?: string;
  children?: ReactNode;
}): JSX.Element => {
  const { className, cardClassName, statistic, description, children } = props;

  return (
    <div className={className}>
      <AppCard className={cardClassName}>
        <div className="font-semibold text-gray-800 text-6xl">{statistic}</div>
        {description && <div className="font-semibold text-gray-500 text-lg pt-2">{description}</div>}
        {children}
      </AppCard>
    </div>
  );
};

export default DataWidget;
