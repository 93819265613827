import { useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { confirmDialog } from 'primereact/confirmdialog';

import { FormContainer, FormInputText, FormPassword, FormSelectButton } from '../../components/form';
import useEffectOnce from '../../hooks/useEffectOnce';
import DuSettingsService from '../../services/duSettingsService';
import { DuSettings } from '../../models/client/du-settings';
import { YesNoOptions } from '../../components/form/FormSelectButton';
import PriceHistory from './PriceHistory';

const EditDuSettings = (props: { clientId: string }): JSX.Element => {
  const { clientId } = props;
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const duSettingsService = DuSettingsService.getInstance();

  useEffectOnce(() => {
    setLoading(true);
    duSettingsService
      .get(clientId)
      .then((client) => {
        reset(client);
        setIsEdit(true);
      })
      .catch((error) => {
        if (error.statusCode === 404) {
          reset(new DuSettings(clientId));
        } else {
          showError(error.message);
        }
      })
      .finally(() => setLoading(false));
  });

  const validationSchema = yup.object({});

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<DuSettings>({
    resolver: yupResolver<any>(validationSchema),
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSubmit = handleSubmit((settings: DuSettings) => {
    confirmDialog({
      message: 'Are you sure you want to save this du settings?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoading(true);
        const addOrUpdate = !isEdit ? duSettingsService.create(settings) : duSettingsService.update(settings);

        setLoading(true);
        addOrUpdate
          .then(() => window.location.reload())
          .catch((error) => {
            showError(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  });

  const navigateToClients = () => {
    navigate('/clients');
  };

  return (
    <FormContainer loading={loading} errors={errors} onSubmit={onSubmit} onCancel={navigateToClients}>
      <div className="!grid grid-cols-2 gap-x-3">
        <FormSelectButton
          control={control}
          errors={formErrors}
          className="pt-4"
          field="isActive"
          label="Is Active"
          options={YesNoOptions}
        />
        <FormInputText control={control} errors={formErrors} field="accountIdentifier" label="Account Identifier" />
        <FormInputText control={control} errors={formErrors} field="softwareProvider" label="Software Provider" />
        <FormPassword control={control} errors={formErrors} field="accountPassword" label="Account Password" />
      </div>
      <Controller
        name="priceHistory"
        control={control}
        render={({ field }) => <PriceHistory priceHistory={field.value} onChange={field.onChange} className="py-2" />}
      />
    </FormContainer>
  );
};

export default EditDuSettings;
