import { useEffect, useState } from 'react';

import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';

import { DataTable, TableDefinition } from '../../components/datatable';
import { BillingItem } from '../../models/billing/billing-item';

type ItemsByService = {
  service: string;
  isExpanded: boolean;
  items: BillingItem[];
};

const BillingByService = (props: { loading: boolean; items: BillingItem[] }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<ItemsByService[]>([]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    const groupsRecord = props.items.reduce((groups: Record<string, BillingItem[]>, value) => {
      if (!value.service) return groups;

      if (!groups[value.service]) {
        groups[value.service] = [value];
      } else {
        groups[value.service].push(value);
      }
      return groups;
    }, {});
    const _groups = Object.entries(groupsRecord).map((group) => {
      return { service: group[0], isExpanded: false, items: group[1] };
    });
    setGroups(_groups);
  }, [props.items]);

  const tableDef: TableDefinition<BillingItem> = {
    columns: [
      {
        field: 'loanNumber',
        header: 'Loan Number',
        dataType: 'text',
        filter: false,
        sortable: false,
      },
      {
        field: 'timestamp',
        header: 'Date',
        dataType: 'date',
        filter: false,
        sortable: false,
      },
      {
        field: 'amount',
        header: 'Amount',
        dataType: 'currency',
        filter: false,
        sortable: false,
      },
    ],
  };

  const onToggle = (entry: ItemsByService) => {
    const index = groups.findIndex((item) => item.service === entry.service);

    entry.isExpanded = !entry.isExpanded;

    let _groups = [...groups];
    _groups[index] = entry;
    setGroups(_groups);
  };

  const itemTemplate = (itemsByLoan: ItemsByService) => {
    const icon = itemsByLoan.isExpanded ? 'pi pi-angle-down' : 'pi pi-angle-right';

    return (
      <div className="col-12 py-3">
        <div className="flex flex-row font-bold">
          <div className="flex flex-grow-1 align-items-center">
            <Button
              className="mr-1"
              icon={icon}
              rounded
              text
              onClick={() => onToggle(itemsByLoan)}
              pt={{
                icon: { className: 'text-black' },
              }}
            />
            <span>Service: {itemsByLoan.service}</span>
          </div>
          <div className="flex justify-content-end gap-8">
            <span>Total Requests: {itemsByLoan.items.length}</span>
            <span>
              Total Cost:{' '}
              {itemsByLoan.items
                .reduce((sum: number, value) => sum + (value.amount ?? 0), 0)
                .toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
            </span>
          </div>
        </div>
        {itemsByLoan.isExpanded && expansionTemplate(itemsByLoan.items)}
      </div>
    );
  };

  const expansionTemplate = (items: BillingItem[]) => {
    return (
      <div className="col-12 px-3 pt-3">
        <DataTable
          headerClassName="text-gray-400 text-[0.925rem]"
          loading={loading}
          tableDef={tableDef}
          data={items}
          paginator={items.length > 10}
          sortMode="single"
          sortField="loanNumber"
        />
      </div>
    );
  };

  return <DataView value={groups} itemTemplate={itemTemplate} paginator={groups.length > 10} rows={10} />;
};

export default BillingByService;
