import { CreditReportingCompany } from './credit-report-transaction';

export class CreditReportResponse {
  creditReferenceNumber?: string;
  lenderLoanNumber?: string;
  creditReportingCompany?: CreditReportingCompany;
  creditReportTotalCost?: number;
  borrowerCreditDetails?: BorrowerCreditDetails[];
  liabilityDetails?: LiabilityDetails;
  documents?: Document[];

  creditReportingAgency?: string;
}

export class BorrowerCreditDetails {
  borrowerId?: string;
  creditScores?: CreditScore[];

  firstName?: string;
  lastName?: string;
}

export class CreditScore {
  score?: number;
  creditBureau?: string;
  creditScoringModel?: string;
  scoreRankPercentile?: number;
}

export class LiabilityDetails {
  liabilities?: Liability[];
  totals?: LiabilityTotal[];
}

export class Liability {
  accountId?: string;
  accountType?: LiabilityAccountType;
  accountStatus?: LiabilityAccountStatus;
  accountOwnershipStatus?: LiabilityAccountOwnershipStatus;
  creditor?: string;
  monthlyPaymentAmount: number = 0;
  unpaidBalanceAmount: number = 0;
  associatedBorrowerIds?: string[];
}

export class LiabilityTotal {
  accountType?: LiabilityAccountType;
  accountStatus?: LiabilityAccountStatus;
  totalMonthlyPaymentAmount: number = 0;
  totalUnpaidBalanceAmount: number = 0;
  count: number = 0;
}

export class Document {
  documentName?: string;
  documentContentType?: DocumentContentType;
  documentContent?: string;
}

export enum LiabilityAccountType {
  CreditLine = 'CreditLine',
  Installment = 'Installment',
  Mortgage = 'Mortgage',
  Open = 'Open',
  Revolving = 'Revolving',
  Unknown = 'Unknown',
}

export enum LiabilityAccountStatus {
  Closed = 'Closed',
  Frozen = 'Frozen',
  Open = 'Open',
  Paid = 'Paid',
  Refinanced = 'Refinanced',
  Transferred = 'Transferred',
}

export enum LiabilityAccountOwnershipStatus {
  AuthorizedUser = 'AuthorizedUser',
  Comaker = 'Comaker',
  Deceased = 'Deceased',
  Individual = 'Individual',
  JointContractualLiability = 'JointContractualLiability',
  JointParticipating = 'JointParticipating',
  Maker = 'Maker',
  OnBehalfOf = 'OnBehalfOf',
  Terminated = 'Terminated',
  Undesignated = 'Undesignated',
}

export enum DocumentContentType {
  Pdf = 'Pdf',
}
