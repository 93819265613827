import ApiService from './apiService';
import { Client } from '../models/client/client';

class ClientService extends ApiService {
  private static instance?: ClientService;
  private static clientsCache?: Client[] | null;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!ClientService.instance) {
      ClientService.instance = new ClientService();
    }
    return ClientService.instance;
  }

  getAll(): Promise<Client[]> {
    if (!ClientService.clientsCache) {
      return this.client.get(`/clients`).then((response) => {
        ClientService.clientsCache = response.data;
        return ClientService.clientsCache!;
      });
    }

    return Promise.resolve(ClientService.clientsCache);
  }

  get(id: string): Promise<Client> {
    return this.client.get(`/clients/${id}`).then((response) => response.data);
  }

  create(client: Client): Promise<void> {
    ClientService.clientsCache = null;
    return this.client.post(`/clients`, client);
  }

  update(client: Client): Promise<void> {
    ClientService.clientsCache = null;
    return this.client.put(`clients/${client.clientId}`, client);
  }

  delete(id: string): Promise<void> {
    ClientService.clientsCache = null;
    return this.client.delete(`/clients/${id}`);
  }
}

export default ClientService;
