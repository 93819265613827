import { ReactNode, useEffect, useState } from 'react';

import { FilterMatchMode } from 'primereact/api';
import {
  DataTableExpandedRows,
  DataTableFilterMeta,
  DataTableFilterMetaData,
  DataTableRowEvent,
  DataTableRowExpansionTemplate,
  DataTableRowGroupHeaderTemplateType,
  DataTableRowToggleEvent,
  DataTableValueArray,
  DataTable as PrimeDataTable,
} from 'primereact/datatable';
import {
  Column,
  ColumnBodyOptions,
  ColumnFilterElementTemplateOptions,
  ColumnFilterMatchModeOptions,
} from 'primereact/column';

import { filterOptions, filterTemplates, initFilter } from '../../utils/data-table-lookups';
import { ToCurrency } from '../../utils/string-helper';

export type DataTableColType = 'text' | 'numeric' | 'currency' | 'date' | 'timestamp' | 'boolean' | 'none';

export type TableDefinition<T extends Record<string, any>> = {
  columns: TableColumn<T>[];
};

export type TableColumn<T extends Record<string, any>> = {
  className?: string;
  field?: string;
  header: string;
  dataType: DataTableColType;
  body?: React.ReactNode | ((data: T, options: ColumnBodyOptions) => React.ReactNode);
  sortable?: boolean;
  filter?: boolean;
  initFilter?: DataTableFilterMetaData;
  filterOptions?: ColumnFilterMatchModeOptions[];
  filterTemplate?: ReactNode | ((options: ColumnFilterElementTemplateOptions) => ReactNode);
};

const DataTable = <T extends Record<string, any>>(props: {
  className?: string;
  loading?: boolean;
  header?: ReactNode;
  headerClassName?: string;
  columnHeaderClassName?: string;
  tableDef: TableDefinition<T>;
  data: T[];
  paginator?: boolean;
  globalFilterValue?: string;
  sortMode?: 'single' | 'multiple';
  sortField?: string;
  isExpansion?: boolean;
  expandedRows?: DataTableValueArray | DataTableExpandedRows;
  onRowToggle?: (event: DataTableRowToggleEvent) => void;
  onRowExpand?: (event: DataTableRowEvent) => void;
  rowExpansionTemplate?: (data: T, options: DataTableRowExpansionTemplate) => React.ReactNode;
  rowGroupMode?: string;
  groupRowsBy?: string;
  rowGroupHeaderTemplate?: DataTableRowGroupHeaderTemplateType<DataTableValueArray>;
  expandableRowGroups?: boolean;
  size?: 'small' | 'normal' | 'large';
}): JSX.Element => {
  const {
    className,
    loading,
    header,
    headerClassName,
    columnHeaderClassName,
    tableDef,
    data,
    paginator,
    globalFilterValue,
    sortMode,
    sortField,
    isExpansion,
    expandedRows,
    onRowToggle,
    onRowExpand,
    rowExpansionTemplate,
    size,
  } = props;

  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(() => {
    const localFilters = tableDef.columns
      .filter((x) => !!x.field)
      .reduce((acc: any, curr) => {
        acc[curr.field!] = curr.initFilter ?? initFilter[curr.dataType];
        return acc;
      }, {});

    const globalFilter = {
      global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    };
    return { ...globalFilter, ...localFilters };
  });

  useEffect(() => {
    const _filters = { ...filters };
    (_filters['global'] as DataTableFilterMetaData).value = globalFilterValue ?? '';
    setFilters(_filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterValue]);

  const getBodyTemplate = (column: TableColumn<T>, data: T) => {
    const value = !!column.field ? data[column.field] : undefined;

    switch (column.dataType) {
      case 'date':
        return value.toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      case 'timestamp':
        return value?.toLocaleString('en-US');
      case 'currency':
        return ToCurrency(value);
      case 'boolean':
        return value ? 'Yes' : 'No';
      default:
        return value;
    }
  };

  return (
    <div className={className}>
      {header && <div className={headerClassName ?? 'mb-5'}>{header}</div>}
      <PrimeDataTable
        loading={loading ?? false}
        filters={filters}
        value={data}
        paginator={paginator ?? true}
        rows={10}
        sortMode={sortMode ?? 'multiple'}
        sortField={sortField}
        expandedRows={expandedRows}
        onRowToggle={onRowToggle}
        onRowExpand={onRowExpand}
        rowExpansionTemplate={rowExpansionTemplate}
        size={size}
      >
        {!!isExpansion && (
          <Column
            expander
            headerClassName={columnHeaderClassName ?? 'bg-transparent text-gray-400 text-[0.925rem]'}
            style={{ width: '5rem' }}
          />
        )}
        {tableDef.columns.map((col, idx) => {
          return (
            <Column
              className={col.className}
              key={idx}
              header={col.header.toUpperCase()}
              headerClassName={columnHeaderClassName ?? 'bg-transparent text-gray-400 text-[0.925rem]'}
              field={col.field}
              body={!!col.body ? col.body : (data: T) => getBodyTemplate(col, data)}
              sortable={col.sortable ?? true}
              filter={col.filter ?? true}
              filterElement={col.filterTemplate ?? filterTemplates[col.dataType]}
              filterMatchModeOptions={col.filterOptions ?? (col.dataType && filterOptions[col.dataType])}
              filterPlaceholder={`Search by ${col.header}`}
              filterHeaderClassName="bg-black"
              filterMenuClassName="p-input-filled"
              showAddButton={false}
              showFilterOperator={false}
            />
          );
        })}
      </PrimeDataTable>
    </div>
  );
};

export default DataTable;
