import { UnderwriteTransaction } from '../models/underwrite/underwrite-transaction';
import ApiService from './apiService';

class UnderwriteService extends ApiService {
  private static instance?: UnderwriteService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!UnderwriteService.instance) {
      UnderwriteService.instance = new UnderwriteService();
    }
    return UnderwriteService.instance;
  }

  searchTransactions(startDate?: Date, endDate?: Date): Promise<UnderwriteTransaction[]> {
    const params = {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    };
    return this.client.get(`underwrite/transactions`, params).then((response) => response.data);
  }
}

export default UnderwriteService;
