import { Control, Controller, FieldErrors, FieldPath, FieldValues, get } from 'react-hook-form';

import { MultiSelect } from 'primereact/multiselect';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { classNames } from 'primereact/utils';

export type FormMultiSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  className?: string;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  field: TName;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  options: SelectItemOptionsType | undefined;
  optionLabel?: string;
  optionValue?: string;
  onChange?: (e: any) => void;
  showFilter?: boolean;
  showClear?: boolean;
};

const FormMultiSelect = <TFieldValues extends FieldValues>({
  ...props
}: FormMultiSelectProps<TFieldValues>): JSX.Element => {
  let {
    className,
    control,
    field,
    errors,
    label,
    disabled,
    placeholder,
    options,
    optionLabel,
    optionValue,
    onChange,
    showFilter,
    showClear,
  } = props;

  const errorTemplate = () => {
    const error = get(errors, field);
    return error && <small className="p-error">{error?.message as string}</small>;
  };

  return (
    <div className={className ?? 'field'}>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames('font-medium', { 'p-error': fieldState.error, disabled })}>
              {label}
            </label>
            <MultiSelect
              id={field.name}
              value={field.value}
              className={classNames({ 'p-invalid': fieldState.error, disabled })}
              disabled={disabled}
              placeholder={placeholder ?? `Select ${label}`}
              options={options}
              optionLabel={optionLabel}
              optionValue={optionValue}
              maxSelectedLabels={3}
              onChange={onChange ?? ((e) => field.onChange(e.value))}
              filter={showFilter}
              showClear={showClear}
            />
          </>
        )}
      />
      {errorTemplate()}
    </div>
  );
};

export default FormMultiSelect;
