import { useState, useEffect } from 'react';

import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { ColumnBodyOptions } from 'primereact/column';

import { ToCurrency } from '../../utils/string-helper';
import { DataTable, TableDefinition } from '../../components/datatable';
import { EffectivePrice } from '../../models/client/effective-price';
import EditEffectivePrice, { EffectivePriceEntry } from './EditEffectivePrice';

const PriceHistory = (props: {
  className?: string;
  priceHistory?: EffectivePrice[];
  onChange: (priceHistory?: EffectivePrice[]) => void;
}): JSX.Element => {
  const { className, onChange: onPriceHistoryChange } = props;

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [priceHistory, setPriceHistory] = useState<EffectivePrice[] | undefined>(props.priceHistory);
  const [editEntry, setEditEntry] = useState<EffectivePriceEntry>({ effectivePrice: new EffectivePrice(), index: -1 });

  useEffect(() => {
    setPriceHistory(props.priceHistory);
  }, [props.priceHistory]);

  const onAdd = () => {
    setEditEntry({ effectivePrice: new EffectivePrice(), index: -1 });
    setShowDialog(true);
  };

  const onEdit = (price: EffectivePrice, index: number) => {
    setEditEntry({ effectivePrice: price, index: index });
    setShowDialog(true);
  };

  const onDelete = (price: EffectivePrice, index: number) => {
    confirmDialog({
      message: `Are you sure you want to delete this price: ${price.effectiveDate.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })} - ${ToCurrency(price.price)}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const history = [...priceHistory!];
        history.splice(index, 1);
        onPriceHistoryChange(history);
      },
    });
  };

  const actionBodyTemplate = (price: EffectivePrice, options: ColumnBodyOptions) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => onEdit(price, options.rowIndex)}
          tooltip="Edit Price"
          tooltipOptions={{ position: 'left' }}
          type="button"
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() => onDelete(price, options.rowIndex)}
          tooltip="Delete Price"
          tooltipOptions={{ position: 'left' }}
          severity="danger"
          type="button"
        />
      </div>
    );
  };

  const tableDef: TableDefinition<EffectivePrice> = {
    columns: [
      {
        field: 'effectiveDate',
        header: 'Effective Date',
        dataType: 'date',
        sortable: false,
        filter: false,
      },
      {
        field: 'price',
        header: 'Price',
        dataType: 'currency',
        sortable: false,
        filter: false,
      },
      {
        header: 'Actions',
        body: actionBodyTemplate,
        dataType: 'none',
        sortable: false,
        filter: false,
      },
    ],
  };

  return (
    <>
      <DataTable
        header={
          <div className="flex flex-col lg:flex-row justify-content-between align-items-center">
            <div className="font-semibold text-base">Price History</div>
            <div>
              <Button icon="pi pi-plus" label="Add New" onClick={onAdd} type="button" outlined size="small" />
            </div>
          </div>
        }
        headerClassName="mb-2"
        tableDef={tableDef}
        data={priceHistory ?? []}
        paginator={false}
        className={className}
        size="small"
      />
      {showDialog && (
        <EditEffectivePrice
          entry={editEntry}
          onSave={(entry) => {
            const history = [...priceHistory!];
            if (entry.index === -1) {
              history.push(entry.effectivePrice);
            } else {
              history[entry.index] = entry.effectivePrice;
            }
            onPriceHistoryChange(history);
            setShowDialog(false);
          }}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </>
  );
};

export default PriceHistory;
