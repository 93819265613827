import { useMatch, NavLink } from 'react-router-dom';

import { classNames } from 'primereact/utils';
import { Item } from '../../../models/sidebar/sidebar';
import { SidebarMenuItemProps } from '../../../models/sidebar/sidebar';

const SidebarMenuItem = (props: SidebarMenuItemProps) => {
  const { id, minimized, label, to, submenuItems, icon, currentMenu, selectMenu } = props;

  return (
    <div className="flex flex-col overflow-hidden">
      <MenuHeader
        id={id}
        minimized={minimized}
        label={label}
        to={to}
        submenuItems={submenuItems}
        icon={icon}
        currentMenu={currentMenu}
        selectMenu={selectMenu}
      />
      {submenuItems && submenuItems.length > 0 && (
        <div
          className={classNames(
            'transition-all duration-300',
            !minimized && currentMenu === id ? 'max-h-[750px] ease-in' : 'max-h-[0px] ease-out'
          )}
        >
          <ul className="list-none overflow-x-hidden overflow-y-auto nav-scrollbar">
            {submenuItems.map(
              (ele: Item, idx: number): JSX.Element => (
                <li key={idx}>
                  <SubmenuItem key={idx} to={ele.to} label={ele.label} />
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

const MenuHeader = (props: SidebarMenuItemProps): JSX.Element => {
  const { id, minimized, label, to, submenuItems, icon, currentMenu, selectMenu } = props;
  const menuHeaderBaseStyle: string = 'flex flex-row items-center cursor-pointer h-[3rem]';
  const urlMatch = useMatch(`${to}/:sub_page`);

  const headerContent = (
    <>
      {icon && <i className={classNames('ml-[calc((var(--sidebar-min-width)_-_4rem)/2)] pi fs-3 icon', icon)}></i>}
      {!minimized && (
        <div className="flex flex-row justify-between items-center ml-3 grow">
          <span
            className={submenuItems && submenuItems.length > 0 ? '' : 'hover:text-[var(--sidebar-link-text-color-hover)]'}
          >
            {label}
          </span>
          {submenuItems && submenuItems.length > 0 && (
            <i
              className={classNames(
                'pi pi-chevron-up duration-300 ease-in-out',
                !minimized && currentMenu === id && 'rotate-180'
              )}
            ></i>
          )}
        </div>
      )}
    </>
  );

  return submenuItems && submenuItems.length > 0 ? (
    <div
      className={classNames(menuHeaderBaseStyle, to !== '' && urlMatch && 'text-[var(--sidebar-link-text-color-active)]')}
      onClick={() => selectMenu(id)}
    >
      {headerContent}
    </div>
  ) : (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? menuHeaderBaseStyle +
            ' bg-[var(--sidebar-link-bg-color-active)] text-[var(--sidebar-link-text-color-active)] rounded-md'
          : menuHeaderBaseStyle
      }
      to={to || ''}
      onClick={() => selectMenu(id)}
    >
      {headerContent}
    </NavLink>
  );
};

const SubmenuItem = (props: { label: string; to: string }): JSX.Element => {
  const { label, to } = props;
  const submenuItemBaseStyle: string = 'flex flex-row items-center rounded py-2.5 ';

  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? submenuItemBaseStyle +
            ' bg-[var(--sidebar-link-bg-color-active)] text-[var(--sidebar-link-text-color-active)] rounded-md'
          : submenuItemBaseStyle
      }
      to={to}
    >
      <i className="w-[2px] h-[2px] border-2 rounded mx-4 border-[var(--sidebar-link-text-color)]"></i>
      <span className="hover:text-[var(--sidebar-link-text-color-hover)]">{label}</span>
    </NavLink>
  );
};

export default SidebarMenuItem;
