import { ChartCoordinates, ChartSeries } from '../models/chart/chart';

export const GetCountByDay = <T extends Record<string, any>, K extends keyof T>(
  source: T[],
  dateField: K
): ChartCoordinates[] => {
  const groups = source.reduce((groups: Record<string, number>, value) => {
    const date = value[dateField].toISOString().split('T')[0];
    if (!groups[date]) {
      groups[date] = 1;
    } else {
      groups[date]++;
    }
    return groups;
  }, {});

  return Object.entries(groups).map((entry) => {
    return { x: entry[0], y: entry[1] };
  });
};

export const GetCountByGroupByDay = <T extends Record<string, any>, K extends keyof T>(
  source: T[],
  dateField: K,
  groupByField: K,
  labelsOverride?: Record<string, string>
): ChartSeries[] => {
  let distinctFieldVals = [...new Set(source.map((ele) => ele[groupByField]))];
  distinctFieldVals = distinctFieldVals.filter((ele) => ele !== undefined);

  return distinctFieldVals.map((fieldVal) => {
    return {
      name: (labelsOverride && labelsOverride[fieldVal]) ?? fieldVal,
      data: GetCountByDay(
        source.filter((x) => x[groupByField] === fieldVal),
        dateField
      ),
    };
  });
};
